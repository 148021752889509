import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UmsService } from '../ums.service';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { from } from 'rxjs';
import { AppComponent } from '../app.component';
declare var $;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [DatePipe]
})
export class LoginComponent implements OnInit {

  Login_form: FormGroup;
  UserLoginList: any;

  submitted: boolean = false;
  UserAccessUrls: any=[];
  constructor(public formBuilder: FormBuilder,
    public UmsApi: UmsService, private router: Router, public datePipe:DatePipe, private myapp: AppComponent) { }

  ngOnInit() {

    // Article add form initialisation
    this.Login_form = this.formBuilder.group({
      UserCode: new FormControl(0),
      UserName: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
      Password1: new FormControl('')
    });

    this.Login_form.controls['UserName'].setValue(localStorage.LS_UserName);
    this.Login_form.controls['Password'].setValue(localStorage.LS_Password);

    this.getDate();
  }


  get Validation() { return this.Login_form.controls };

  LoginUser() {
    this.submitted = true;
    if (this.Login_form.invalid) {
      return;
    }
    this.submitted = false;

    if ($('#ChkRemember').prop("checked") == false) {
      localStorage.setItem('LS_UserName', "");
      localStorage.setItem('LS_Password', "");
    }

    var CTime = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();

    document.getElementById('overLay').style.display = "block";

      //Password Encryption
      //alert("psw="+JSON.stringify(this.Login_form.value.Password))

      var encrypt=btoa(this.Login_form.value.Password);
      console.log("encrypt psw=="+encrypt);
      //pass encrypted value
      this.Login_form.value.Password1=encrypt;

      var decrypt=atob(encrypt)
      console.log("decrypt psw=="+decrypt);

    this.UmsApi.LoginUser(this.Login_form.value).subscribe(response => {
      
    document.getElementById('overLay').style.display = "none";
      {
       
        if(response.result == null)
        {
          alert("Invalid UseName Password")
        }
        this.UserLoginList = response.result;

        console.log(" this.UserLoginList==>"+JSON.stringify( this.UserLoginList))
        // localStorage.setItem('Token',response.result);
        if (this.UserLoginList.length == 0) {
          alert("Invalid UseName Password");
          return;
        } else {
          this.getPrivileges(this.UserLoginList);
        }
      }
    
    }, error => {
      console.log(error);
    });
  }

  getDate() {
    var date1 = formatDate(new Date(), 'dd/MM/yyyy', 'en_US');
    if (Date.parse(date1).toString() == Date.parse('08/08/2020').toString()) {
     document.getElementById('LoginId1').innerHTML = '';  
     setTimeout(() => {
      window.location.reload;
     }, 1000);
   
    }
  }

  getPrivileges(UserLoginList){
    // var data={
    //   User_Code:  UserLoginList[0].UserCode,
    //   Token : localStorage.Token
    // }
    // this.UmsApi.getPrivileges(UserLoginList).subscribe(response => {
      // if (response.status) {

        localStorage.setItem('LS_RoleId', UserLoginList[0].roleId);
        localStorage.setItem('LS_SchoolId', UserLoginList[0].schoolId);
        localStorage.setItem('LS_UserId', UserLoginList[0].userCode);
        localStorage.setItem('Role', 'U');
        
        localStorage.setItem('LS_SchoolName', UserLoginList[0].schoolName);
        localStorage.setItem('LS_SchoolAddress', UserLoginList[0].address);
        localStorage.setItem('LS_SchoolPinCode', UserLoginList[0].pinCode);
        localStorage.setItem('LS_SchoolContactNo', UserLoginList[0].xontactNo);
        localStorage.setItem('LS_SchoolLogoPath', UserLoginList[0].logoPath);
        localStorage.setItem('LS_SchoolAbbrevation', UserLoginList[0].abbrevation);
        localStorage.setItem('LS_SchoolWebsite', UserLoginList[0].website);
     
        localStorage.setItem('LocalStorageSessionId', UserLoginList[0].sessionId);
        this.myapp.getUserAccessUrls(1,UserLoginList[0].userCode);
        this.router.navigate(['']);

        setTimeout(() => {
          $("#HeaderId").show();
          $("#SidebarId").show();
          var element = document.getElementById("ContentWp");
          element.classList.add("content-wrapper");
        }, 100);

      // }
      // else
      // {
      //   alert(response.error.message)
      //   this.myapp.Logout();
      // }
    // })
  }

  formreset() {
    this.Login_form.reset({
      UserCode: 0,
      UserName: '',
      Password: ''

    });
  }

  CheckRemember(event) {
    if (event.target.checked) {
      localStorage.setItem('LS_UserName', this.Login_form.value.UserName);
      localStorage.setItem('LS_Password', this.Login_form.value.Password);

    } else {
      localStorage.setItem('LS_UserName', "");
      localStorage.setItem('LS_Password', "");
    }


  }

  OnEnterLogin(event) {
    if (event.key == 'Enter') {
      this.LoginUser();
    }
  }


  // getUserAccessUrls() {
  //   this.UmsApi.getUserAccessUrls(1).subscribe(response => {
  //     if (response.status) {
  //       this.UserAccessUrls = response.data.UserAccessUrls;
  //       for (let i in this.UserAccessUrls) {
  //         for (let j in this.UserAccessUrls[i].subMenu) {
  //           this.UserAccessUrls[i].subMenu[j].urlList = this.makeSubArrays(this.UserAccessUrls[i].subMenu, this.UserAccessUrls[i].subMenu[j].subMenu);
  //         }
  //       }
        
  //       for (let i in this.UserAccessUrls) {
  //         for (let j in this.UserAccessUrls[i].subMenu) {
  //           this.UserAccessUrls[i].subMenu[j].urlList = this.makeSubArrays(this.UserAccessUrls[i].subMenu, this.UserAccessUrls[i].subMenu[j].subMenu);
  //         }
  //       }
     
  //       this.router.navigate(['']);

  //       setTimeout(() => {
  //         $("#HeaderId").show();
  //         $("#SidebarId").show();
  //         var element = document.getElementById("ContentWp");
  //         element.classList.add("content-wrapper");
  //       }, 100);

  //       this.checkLastWorkingDate();
  //     }
  //     else {
  //       this.UserAccessUrls = null;
  //     }
  //   }, error => {
  //     console.log(error);
  //   });
  // }

  // makeSubArrays(ArrayLis, subMenu) {
  //   var urlList = [];
  //   for (let i in ArrayLis) {
  //     if (subMenu == ArrayLis[i].subMenu) {
  //       urlList.push({
  //         "url": ArrayLis[i].url,
  //         "urlName": ArrayLis[i].urlName,
  //       })
  //     }
  //   }
  //   return urlList;
  // }

}
