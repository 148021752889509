import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
  MatToolbarModule, MatMenuModule, MatIconModule,MatTabsModule,
  MatProgressSpinnerModule, MatSidenavModule, MatListModule, MatGridListModule, MatProgressBarModule, MatBadgeModule, MatFormFieldModule, MatSelectModule,
  MatDatepickerModule, MatNativeDateModule, MatPaginatorModule, MatExpansionModule, MatSortModule
} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatTabsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  exports: [
    MatProgressBarModule,
    CommonModule,
    MatTabsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
  ],
})
export class MaterialModule { }

// import { NgModule } from "@angular/core";
// import { CommonModule } from '@angular/common';
// import {
//   MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
//   MatToolbarModule, MatMenuModule, MatIconModule,MatTabsModule,
//   MatProgressSpinnerModule, MatSidenavModule, MatListModule, MatGridListModule, MatProgressBarModule, MatBadgeModule, MatFormFieldModule, MatSelectModule,
//   MatDatepickerModule, MatNativeDateModule
// } from '@angular/material';
// @NgModule({
//   imports: [
//     CommonModule,
//     MatGridListModule,
//     MatToolbarModule,
//     MatButtonModule,
//     MatCardModule,
//     MatInputModule,
//     MatDialogModule,
//     MatTableModule,
//     MatMenuModule,
//     MatTabsModule,
//     MatIconModule,
//     MatProgressSpinnerModule,
//     MatSidenavModule,
//     MatListModule,
//     MatProgressBarModule,
//     MatBadgeModule,
//     MatFormFieldModule,
//     MatSelectModule,
//     MatDatepickerModule,
//     MatNativeDateModule 
//   ],
//   exports: [
//     MatProgressBarModule,
//     CommonModule,
//     MatTabsModule,
//     MatToolbarModule,
//     MatButtonModule,
//     MatCardModule,
//     MatInputModule,
//     MatDialogModule,
//     MatTableModule,
//     MatMenuModule,
//     MatIconModule,
//     MatProgressSpinnerModule,
//     MatSidenavModule,
//     MatListModule,
//     MatGridListModule,
//     MatBadgeModule,
//     MatFormFieldModule,
//     MatSelectModule,
//     MatDatepickerModule,
//     MatNativeDateModule 
//   ],
// })
// export class MaterialModule { }