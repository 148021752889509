import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UmsService } from '../ums.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
declare var $;
@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.css']
})
export class StudentLoginComponent implements OnInit {
  StudLogin_form: FormGroup;
  UserLoginList: any;

  submitted: boolean = false;
  constructor(public formBuilder: FormBuilder,
    public UmsApi: UmsService, private router: Router, private myapp: AppComponent) { }

  ngOnInit() {

    // Article add form initialisation
    this.StudLogin_form = this.formBuilder.group({
      UserCode: new FormControl(0),
      UserName: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required)
    });
    this.StudLogin_form.controls['UserName'].setValue(localStorage.LS_StudUserName);
    this.StudLogin_form.controls['Password'].setValue(localStorage.LS_StudPassword);

  }

  get Validation() { return this.StudLogin_form.controls };

  StudentLogin() {
    this.submitted = true;
    if (this.StudLogin_form.invalid) {
      return;
    }
    this.submitted = false;

    if ($('#StudChkRemember').prop("checked") == false) {
      localStorage.setItem('LS_StudUserName', "");
      localStorage.setItem('LS_StudPassword', "");
    }

    var CTime = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
   
    this.UmsApi.LoginStudent(this.StudLogin_form.value).subscribe(response => {
      
      if (response.status) {
        this.UserLoginList = response.data.LoginData;
        localStorage.setItem('Token',response.data.LoginData.token);
        if (this.UserLoginList.length == 0) {
          alert("Invalid UseName Password");
          return;
        } else {
          this.getPrivileges(this.UserLoginList);
        }
      }
      else {
        this.UserLoginList = null;
      }
    }, error => {
      console.log(error);
    });
  }

  formreset() {
    this.StudLogin_form.reset({
      UserCode: 0,
      UserName: '',
      Password: ''

    });
  }

  CheckRemember(event) {
    if (event.target.checked) {
      localStorage.setItem('LS_StudUserName', this.StudLogin_form.value.UserName);
      localStorage.setItem('LS_StudPassword', this.StudLogin_form.value.Password);

    } else{
      localStorage.setItem('LS_StudUserName', "");
      localStorage.setItem('LS_StudPassword', "");
    }


  }

  getPrivileges(UserLoginList){
    var data={
      User_Code: UserLoginList[0].Student_LogIn_Id,
      Token : localStorage.Token
    }
    this.UmsApi.getPrivileges(data).subscribe(response => {
      
      if (response.status) {

        localStorage.setItem('LS_SchoolId', UserLoginList[0].SchoolId);
        localStorage.setItem('LS_StudentId', UserLoginList[0].StudentId);
        localStorage.setItem('LS_Student_LogIn_Id', UserLoginList[0].Student_LogIn_Id);
        this.router.navigate(['']);
      //  this.myapp.getUserAccessUrls(3,0);
        setTimeout(() => {
          $("#HeaderId").show();
          $("#SidebarId").show();
          var element = document.getElementById("ContentWp");
          element.classList.add("content-wrapper");
        }, 100);
      }
      else
      {
        alert(response.error.message)
        this.myapp.Logout();
      }
    })
  }
}
