import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UmsService } from '../ums.service';
import { Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-change-student-password',
  templateUrl: './change-student-password.component.html',
  styleUrls: ['./change-student-password.component.css']
})
export class ChangeStudentPasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted : boolean = false;
  passwordDontMatch : any;
  constructor(private router: Router,public formBuilder: FormBuilder,
    public UmsApi: UmsService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      SchoolId: (localStorage.LS_SchoolId),
      userId: (localStorage.LS_StudentId),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });
    this.passwordDontMatch = "";
  }
  get f() { return this.changePasswordForm.controls };
  updatePassword()
  {
    this.submitted = true;
    if(this.changePasswordForm.invalid)
    {
      return;
    }
    if(this.changePasswordForm.value.newPassword == this.changePasswordForm.value.confirmPassword)
    {
      this.passwordDontMatch = "";
    }
    else
    {
      this.passwordDontMatch = "Password don't match";
      return
    }
    this.submitted = false;
    this.UmsApi.updateStudentPassword(this.changePasswordForm.value).subscribe(response => {
      if (response.status) {
        alert(response.data.Password.message);
        if(response.data.Password.error == "No")
        {
          this.Logout();
        }
      }
    }, error => {
      console.log(error);
    });
  }
  Logout() {
    localStorage.LS_RoleId = '';
    localStorage.LS_SchoolId = '';
    localStorage.LS_UserId = '';

    this.router.navigate(['/UserLogin']);

    setTimeout(() => {
      $("#HeaderId").hide();
      $("#SidebarId").hide();
      var element = document.getElementById("ContentWp");
      element.classList.remove("content-wrapper");
    }, 100);

  }
}
