import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UmsService } from '../ums.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { DatePipe, formatDate } from '@angular/common';
declare var $;

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-emp-login',
  templateUrl: './emp-login.component.html',
  styleUrls: ['./emp-login.component.css'],
  providers: [DatePipe]
})
export class EmpLoginComponent implements OnInit {
  EmpLogin_form: FormGroup;
  UserLoginList: any;

  submitted: boolean = false;
  UserAccessUrls: any = [];

  constructor(public formBuilder: FormBuilder,
    public UmsApi: UmsService, private router: Router, public datePipe:DatePipe, private myapp : AppComponent) { }

  ngOnInit() {

    // Article add form initialisation
    this.EmpLogin_form = this.formBuilder.group({
      UserCode: new FormControl(0),
      UserName: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
      Password1: new FormControl('')
    });

    this.EmpLogin_form.controls['UserName'].setValue(localStorage.LS_EmpUserName);
    this.EmpLogin_form.controls['Password'].setValue(localStorage.LS_EmpPassword);

  }

  get Validation() { return this.EmpLogin_form.controls };

  EmployeeLogin() {
    this.submitted = true;
    if (this.EmpLogin_form.invalid) {
      return;
    }
    this.submitted = false;
    document.getElementById('overLay').style.display = "block";

    if ($('#EmpChkRemember').prop("checked") == false) {
      localStorage.setItem('LS_EmpUserName', "");
      localStorage.setItem('LS_EmpPassword', "");
    }

    var CTime = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();

    //Password Encryption
    //alert("psw="+JSON.stringify(this.EmpLogin_form.value.Password))

    var encrypt=btoa(this.EmpLogin_form.value.Password);
    console.log("encrypt psw=="+encrypt);
    //pass encrypted value
    this.EmpLogin_form.value.Password1=encrypt;

    var decrypt=atob(encrypt)
    console.log("decrypt psw=="+decrypt);

    this.UmsApi.LoginUserEmp(this.EmpLogin_form.value).subscribe(response => {

      document.getElementById('overLay').style.display = "none";
       {

        if(response.result == null)
        {
          alert("Invalid UseName Password")
        }
        
        this.UserLoginList = response.result; 
        // localStorage.setItem('Token',response.data.LoginData.token);
        if (this.UserLoginList.length == 0) {
          alert("Invalid UseName Password");
          return;
        } else {
          this.getPrivileges(this.UserLoginList);
        }
      }
    
    }, error => {
      console.log(error);
    });
  }


  formreset() {
    this.EmpLogin_form.reset({
      UserCode: 0,
      UserName: '',
      Password: ''

    });
  }

  CheckRemember(event) {
    if (event.target.checked) {
      localStorage.setItem('LS_EmpUserName', this.EmpLogin_form.value.UserName);
      localStorage.setItem('LS_EmpPassword', this.EmpLogin_form.value.Password);

    } else {
      localStorage.setItem('LS_EmpUserName', "");
      localStorage.setItem('LS_EmpPassword', "");
    }


  }
    OnEnterLogin(event) {
      if (event.key == 'Enter') {
        this.EmployeeLogin();
      }
    }

    getPrivileges(UserLoginList)
    {
      // var data={
      //   User_Code: UserLoginList[0].employeeId,
      //   Token : localStorage.Token
      // }
      // this.UmsApi.getPrivileges(data).subscribe(response => {
        
        // if (response.status) {

      
          localStorage.setItem('LS_RoleId', UserLoginList[0].roleId);
          localStorage.setItem('LS_SchoolId', UserLoginList[0].schoolId);
          localStorage.setItem('LS_UserId', UserLoginList[0].employeeId);
          localStorage.setItem('LS_DepartmentId', UserLoginList[0].departmentId);
          localStorage.setItem('LS_DesignationId', UserLoginList[0].designationId);
          localStorage.setItem('LS_UserCode', UserLoginList[0].userCode);

          //  this.myapp.getUserAccessUrls(1,14);

            this.myapp.getUserAccessUrls(2,localStorage.LS_UserCode);


          //  this.myapp.getUserAccessUrls();
          localStorage.setItem('LS_SchoolName', UserLoginList[0].schoolName);
          localStorage.setItem('LS_SchoolAddress', UserLoginList[0].address);
          localStorage.setItem('LS_SchoolPinCode', UserLoginList[0].pinCode);
          localStorage.setItem('LS_SchoolContactNo', UserLoginList[0].contactNo);
          localStorage.setItem('LS_SchoolLogoPath', UserLoginList[0].logoPath);
          localStorage.setItem('LS_SchoolAbbrevation', UserLoginList[0].abbrevation);
          localStorage.setItem('LS_SchoolWebsite', UserLoginList[0].website);
  
          localStorage.setItem('LocalStorageSessionId', UserLoginList[0].sessionId);

          this.router.navigate(['']);
          this.checkLastWorkingDate();

          setTimeout(() => {
            $("#HeaderId").show();
            $("#SidebarId").show();
            var element = document.getElementById("ContentWp");
            element.classList.add("content-wrapper");
          }, 100);
        }
        // else
        // {
        //   alert(response.error.message);
        //   this.myapp.Logout();
        // }
      // })
    
    
    checkLastWorkingDate()
    {    
      var currentDate;
      currentDate = new Date();
      currentDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
      this.UmsApi.checkLastWorkingDate(currentDate).subscribe(response => {
        if (response.result) {
          
        }
      }, error => {
        console.log(error);
      });
    }
}
