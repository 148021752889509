import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { NgpSortModule } from "ngp-sort-pipe";

import { UserIdleModule } from 'angular-user-idle';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { EmpLoginComponent } from './emp-login/emp-login.component';
import { StudentLoginComponent } from './student-login/student-login.component';

import { AppRoutingModule } from './/app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/material.module';
import { MatPaginatorModule, MatExpansionModule } from '@angular/material';
import { MatSortModule } from '@angular/material/sort'; 
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeStudentPasswordComponent } from './change-student-password/change-student-password.component';
import { JsonvalidationComponent } from './jsonvalidation/jsonvalidation.component';
//import { MultiplecollegeadminComponent } from './multiplecollegeadmin/multiplecollegeadmin.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    OnlyNumbersDirective,
    LoginComponent,
    EmpLoginComponent,
    StudentLoginComponent,
    SidebarComponent,
    ChangePasswordComponent,
    ChangeStudentPasswordComponent,
    JsonvalidationComponent,
    //MultiplecollegeadminComponent,
   
  ],
  imports: [
    BrowserModule,
    ChartsModule,
    NgpSortModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatPaginatorModule,
    MatExpansionModule,  
    NgxBarcode6Module,
    MatSortModule,
     UserIdleModule.forRoot({idle: 60, timeout: 10, ping: 12})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
