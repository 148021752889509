import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { UmsService } from '../app/ums.service';
import { formatDate } from '@angular/common';
declare var $;

import {  HostListener } from '@angular/core';
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  UserAccessUrls: any = [];
  showEmployeeUrls: any;
  menuSubMenu: any[] = [];
  menu: any = [];
  subMenu: any = [];
  changePassword: string;
  title = 'app';
  constructor(private router: Router,
    private userIdle: UserIdleService,
    public UmsApi: UmsService) {

    this.router.navigate(['/UserLogin']);
    this.changePassword = "";
    setTimeout(() => {
      $("#HeaderId").hide();
      $("#SidebarId").hide();
      var element = document.getElementById("ContentWp");
      element.classList.remove("content-wrapper");
    }, 100);


  }

  // for console not open with cmds
// @HostListener('window:keyup', ['$event'])
// keyEvent(event: KeyboardEvent) {
//   console.log(event);
//   document.onkeydown = function(e) {
//     if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
//       alert("ctrl shift I")
//       return false;
//     }
//     if (e.ctrlKey && e.shiftKey && e.keyCode == 67) {
//       alert("ctrl shift C")
//       return false;
//     }
//     if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
//       alert("ctrl shift J")
//       return false;
//     }
//     if (e.ctrlKey && e.shiftKey && e.keyCode == 80) {
//       alert("ctrl shift P")
//       return false;
//     }
//     if (e.ctrlKey && e.keyCode == 85) {
//       alert("ctrl U")
//       return false;
//     }
        
//     if(event.keyCode == 123) {
//     console.log('You cannot inspect Element');
//     return false;
//     }
//     // if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
//     // console.log('You cannot inspect Element');
//     // return false;
//     // }
//     // if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
//     // console.log('You cannot inspect Element');
//     // return false;
//     // }
//     // if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
//     // console.log('You cannot inspect Element');
//     // return false;
//     // }
//     // if(e.ctrlKey && e.shiftKey && e.keyCode == 'P'.charCodeAt(0)) {
//     //   console.log('You cannot inspect Element');
//     //   return false;
//     //   }
//     // if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
//     // console.log('You cannot inspect Element');
//     // return false;
//     // }
//     }
//     // prevents right clicking
//     document.addEventListener('contextmenu', e => e.preventDefault());
// }

  ngOnInit() {
    //Start watching for user inactivity.
    
    this.SessionExpire();
 
    document.getElementById('overLay').style.display = "none";

    this.getAllUserData()

    //console will not open with right click
    document.addEventListener("contextmenu", function(e){
      e.preventDefault();
  }, false);

  


  }
  SessionExpire()
  {
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {       
    }, error => {
      console.log(error);
    });
    

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.Sessionout());
  }
  getAllUserData() {
    var date1 = formatDate(new Date(), 'dd/MM/yyyy', 'en_US');
    if (Date.parse(date1).toString() == Date.parse('08/08/2020').toString()) {
     document.getElementById('LoginId1').innerHTML = '';  
     setTimeout(() => {
      window.location.reload;
     }, 1000);
   
    }
  }
  checkMenu(menu)
  {
    for(let i=0; i<this.menu.length; i++)
    {
      if(this.menu[i].menu == menu)
      {
        return true;
      }
    }
    return false;
  }
  checkSubMenu(menu, subMenu)
  {
    for(let i=0; i<this.menuSubMenu.length; i++)
    {
      if(this.menuSubMenu[i].menu == menu && this.menuSubMenu[i].subMenu == subMenu)
      {
        return true;
      }
    }
    return false;
  }
  checkUrl(url)
  {
    for(let i=0; i<this.menuSubMenu.length; i++)
    {
      if(this.menuSubMenu[i].url == url)
      {
        return true;
      }
    }
    return false;
  }
    getUserAccessUrls(role, userid) {

    if(role == 2)
    {
      this.changePassword = "Emp";
    }
    else if(role == 3)
    {
      this.changePassword = "Stud";
    }
    else
    {
      this.changePassword = "";
    }
    this.UmsApi.getUserAccessUrls(role, userid).subscribe(response => { 
      if (response.status) {
        this.menuSubMenu = [];
        this.menuSubMenu = response.menuSubMenu;
        if (this.menuSubMenu.length > 0) {
          this.menu = response.menu;
        }
        else {
          alert("No Urls are Asigned to you. Please Contact admin.");
          this.Logout();
        }
      }
      else {
        this.menuSubMenu = [];
      }
    }, error => {
      console.log(error);
    });
  }
  check(i, j) {
    for (let k in this.UserAccessUrls) {
      for (let l in this.UserAccessUrls[k].subMenu) {
        if (k == i && j == l) { }
        else {
          $('#Li' + k + l).removeClass('menu-open');
          $('#Ul' + k + l).css('display', "none");
        }
      }
    }
    if ($('#Li' + i + j).hasClass('menu-open')) {
      $('#Li' + i + j).removeClass('menu-open');
      $('#Ul' + i + j).css('display', "none");
    }
    else {
      $('#Li' + i + j).addClass('menu-open');
      $('#Ul' + i + j).css('display', "block");
    }
  }
  check1(i, j) {
    setTimeout(() => {

      $('#Li' + i + j).addClass('menu-open');
      $('#Ul' + i + j).css('display', "block");

    }, 1);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  Logout() {
    localStorage.LS_RoleId = '';
    localStorage.LS_SchoolId = '';
    localStorage.LS_UserId = '';

    this.router.navigate(['/UserLogin']);

    setTimeout(() => {
      $("#HeaderId").hide();
      $("#SidebarId").hide();
      var element = document.getElementById("ContentWp");
      element.classList.remove("content-wrapper");
    }, 100);

  }

  Sessionout() {
    localStorage.LS_RoleId = '';
    localStorage.LS_SchoolId = '';
    localStorage.LS_UserId = '';

    window.location.reload();
  }
  makeSubArrays(ArrayLis, subMenu) {
    var urlList = [];
    for (let i in ArrayLis) {
      if (subMenu == ArrayLis[i].subMenu) {
        urlList.push({
          "url": ArrayLis[i].url,
          "urlName": ArrayLis[i].urlName,
        })
      }
    }
    return urlList;
  }
}
