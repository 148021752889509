import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UmsService {
  // getuserAccessUrls(arg0: number) {
  //   throw new Error('Method not implemented.');
  // }

  // baseUrl = "http://localhost:9001";

   baseUrl = "http://148.72.245.102:9001";
  //http://148.66.128.146/
  constructor(private http: HttpClient) { }

  public fileUpload(url:  string,formData: File)
  {
   
    const uploadfile: FormData = new FormData();  
    
    uploadfile.append('uploadfile', formData);  
   
    return this.http.post(this.baseUrl+url, uploadfile);
   }
  SaveCourse(Course): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCourse`, Course)
  }

  GetCourse(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCourse`)
  }

  UpdateCourse(Course): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCourse`,Course)
  }

  DeleteCourse(Course): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCourse?id=${Course.courseId}`)
  }

  SaveCountry(Country): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCountry`, Country)
  }

  GetCountry(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCountry`)
  }

  UpdateCountry(Country): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCountry?id=${Country.countryId}`, Country)
  }

  DeleteCountry(Country): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCountry?id=${Country.countryId}`)
  }

  GetState(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getState`)
  }

  SaveState(State): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveState`, State)
  }

  UpdateState(State): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateState?id=${State.stateId}`, State)
  }

  DeleteState(State): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteState?id=${State.stateId}`)
  }

  GetStateByCountry(District): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStateByCountry?id=${District.countryId}`, District)
  }

  SaveDistrict(District): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveDistrict`, District)
  }

  GetDistrict(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDistrict`)
  }

  UpdateDistrict(District): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateDistrict?id=${District.districtId}`, District)
  }

  DeleteDistrict(District): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteDistrict?id=${District.districtId}`)
  }

  GetDistrictByState(City): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDistrictByState?id=${City.stateId}`, City)
  }

  SaveCity(City): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCity`, City)
  }

  GetCity(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCity`)
  }

  UpdateCity(City): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCity?id=${City.cityId}`, City)
  }

  DeleteCity(City): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCity?id=${City.cityId}`)
  }

  GetReligion(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getReligion`)
  }

  SaveReligion(Religion): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveReligion`, Religion)
  }

  UpdateReligion(Religion): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateReligion?id=${Religion.religionId}`, Religion)
  }

  DeleteReligion(Religion): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteReligion?id=${Religion.religionId}`)
  }

  GetBranch(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBranch`)
  }

  SaveBranch(Branch): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBranch`, Branch)
  }

  UpdateBranch(Branch): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateBranch`, Branch)
  }
 
  DeleteBranch(Branch): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteBranch?id=${Branch.branchId}`)
  }

  GetSession(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSession`)
  }

  SaveSession(Session): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterSession`, Session)
  }

  UpdateSession(Session): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSession`, Session)
  }

  DeleteSession(Session): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSession?id=${Session.sessionId}`)
  }

  GetCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCategory`)
  }

  SaveCategory(Category): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCategory`, Category)
  }

  UpdateCategory(Category): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCategory?id=${Category.categoryId}`, Category)
  }

  DeleteCategory(Category): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCategory?id=${Category.categoryId}`)
  }

  GetCast(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCast`)
  }

  SaveCast(Cast): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCast`, Cast)
  }

  UpdateCast(Cast): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCast?id=${Cast.castId}`, Cast)
  }

  DeleteCast(Cast): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCast?id=${Cast.castId}`)
  }

  GetSubCast(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubCast`)
  }

  SaveSubCast(SubCast): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveSubCast`, SubCast)
  }

  UpdateSubCast(SubCast): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSubCast?id=${SubCast.subCastId}`, SubCast)
  }

  DeleteSubCast(SubCast): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSubCast?id=${SubCast.subCastId}`)
  }

  GetBranchByCourse(Year): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBranchByCourse?id=${Year.courseId}`)
  }

  GetYear(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getYear`)
  }

  SaveYear(Year): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveYear`, Year)
  }


  UpdateYear(Year): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateYear`, Year)
  }

  DeleteYear(Year): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteYear?id=${Year.yearId}`)
  }


  //priya has changed
  GetYearByBranch(semester): Observable<any> {
    return this.http.get(`${this.baseUrl}/getYearByBranch?id=${semester.branchId}`)
  }






  GetSemester(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSemester`)
  }

  SaveSemester(Semester): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveSemester`, Semester)
  }


  UpdateSemester(Semester): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSemester`,Semester)
  }

  DeleteSemester(Semester): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSemester?id=${Semester.semesterId}`)
  }


  GetSemByYear(SessionWiseSem): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSemByYear?id=${SessionWiseSem.yearId}`)
  }

  GetSessionWiseSem(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSessionWiseSem`)
  }

  SaveSessionWiseSem(SessionWiseSem): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveSessionWiseSem`, SessionWiseSem)
  }


  UpdateSessionWiseSem(SessionWiseSem): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSessionWiseSem`, SessionWiseSem)
  }

  DeleteSessionWiseSem(SessionWiseSem): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSessionWiseSem?id=${SessionWiseSem.sessionWise_SemId}`)
  }

  GetBatch(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBatch`)
  }

  SaveBatch(Batch): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBatch`, Batch)
  }


  UpdateBatch(Batch): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateBatch?id=${Batch.batchId}`, Batch)
  }

  DeleteBatch(Batch): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteBatch?id=${Batch.batchId}`)
  }

  GetTTSubject(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTTSubject`)
  }

  SaveTTSubject(TTSubject): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveTTSubject`, TTSubject)
  }

  UpdateTTSubject(TTSubject): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateTTSubject`, TTSubject)
  }

  DeleteTTSubject(TTSubject): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteTTSubject?id=${TTSubject.ttsubjectId}`)
  }

  GetStream(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStream`)
  }

  SaveStream(Stream): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStream`, Stream)
  }

  UpdateStream(Stream): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateStream?id=${Stream.streamId}`, Stream)
  }

  DeleteStream(Stream): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteStream?id=${Stream.streamId}`)
  }

  GetTimeMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTimeMaster`)
  }

  SaveTimeMaster(TimeMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveTimeMaster`, TimeMaster)
  }

  UpdateTimeMaster(TimeMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateTimeMaster`, TimeMaster)
  }

  DeleteTimeMaster(TimeMaster): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteTimeMaster?id=${TimeMaster.timeId}`)
  }

  GetEntranceExam(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEntranceExam`)
  }

  SaveEntranceExam(TimeMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveEntranceExam`, TimeMaster)
  }

  UpdateEntranceExam(EntranceExam): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEntranceExam?id=${EntranceExam.entranceId}`, EntranceExam)
  }

  DeleteEntranceExam(EntranceExam): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteEntranceExam?id=${EntranceExam.entranceId}`)
  }

  GetQualification(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQualification`)
  }

  SaveQualification(Qualification): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveQualification`, Qualification)
  }

  UpdateQualification(Qualification): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateQualification?id=${Qualification.qualificationId}`, Qualification)
  }

  DeleteQualification(Qualification): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteQualification?id=${Qualification.qualificationId}`)
  }

  GetCertificate(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCertificate`)
  }

  SaveCertificate(Certificate): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCertificate`, Certificate)
  }

  UpdateCertificate(Certificate): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateCertificate?id=${Certificate.certificateId}`, Certificate)
  }

  DeleteCertificate(Certificate): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteCertificate?id=${Certificate.certificateId}`)
  }

  GetHoliday(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHoliday`)
  }

  SaveHoliday(Holiday): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHoliday`, Holiday)
  }

  UpdateHoliday(Holiday): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHoliday?id=${Holiday.holidayId}`, Holiday)
  }

  DeleteHoliday(Holiday): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHoliday?id=${Holiday.holidayId}`)
  }

  GetLanguage(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLanguage`)
  }

  SaveLanguage(Language): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLanguage`, Language)
  }

  UpdateLanguage(Language): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLanguage?id=${Language.mtid}`, Language)
  }

  DeleteLanguage(Language): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLanguage?id=${Language.mtid}`)
  }

  GetFeesCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeesCategory`)
  }

  SaveFeesCategory(FeesCategory): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveFeesCategory`, FeesCategory)
  }

  UpdateFeesCategory(FeesCategory): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateFeesCategory?id=${FeesCategory.feesCategoryId}`, FeesCategory)
  }

  DeleteFeesCategory(FeesCategory): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteFeesCategory?id=${FeesCategory.feesCategoryId}`)
  }

  GetFeesType(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeesType`)
  }

  SaveFeesType(FeesType): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveFeesType`, FeesType)
  }

  UpdateFeesType(FeesType): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateFeesType?id=${FeesType.feesHeadId}`, FeesType)
  }

  DeleteFeesType(FeesType): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteFeesType?id=${FeesType.feesHeadId}`)
  }

  GetSessionalHoliday(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSessionalHoliday`)
  }
  SaveSessionalHoliday(SessionalHoliday): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveSessionalHoliday`, SessionalHoliday)
  }
  UpdateSessionalHoliday(SessionalHoliday): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSessionalHoliday?id=${SessionalHoliday.holidayId}`, SessionalHoliday)
  }

  DeleteSessionalHoliday(SessionalHoliday): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSessionalHoliday?id=${SessionalHoliday.holidayId}`)
  }



  GetNextFeeId(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getNextFeeId`)
  }

  SaveFeeDetails(FeeDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveFeeDetails`, FeeDetails)
  }

  GetFeeMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeMaster`)
  }

  GetFeeTransData(MasterFee): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeTransData?id=${MasterFee.feesId}`)
  }

  UpdateFeeDetails(MasterFee): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateFeeDetails?id=${MasterFee.feesId}`, MasterFee)
  }

  DeleteFeeMaster(MasterFee): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteFeeMaster?id=${MasterFee.feesId}`)
  }

  GetCityByDistrict(StudentNewAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCityByDistrict?id=${StudentNewAdmission.districtId}`, StudentNewAdmission)
  }

  GetAdmissionFeeDetails(StudentNewAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAdmissionFeeDetails?id=${StudentNewAdmission.feesCategoryId}&SessionId=${StudentNewAdmission.sessionId}&CourseId=${StudentNewAdmission.courseId}&BranchId=${StudentNewAdmission.branchId}&YearId=${StudentNewAdmission.yearId}`, StudentNewAdmission)
  }

  SaveStudentNewAdmission(StudentNewAdmission): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentNewAdmission`, StudentNewAdmission)
  }

  GetStudentAdmissionList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentAdmissionList`)
  }

//Addes by prajakta for already exist username in student admission

CheckStudentUserName(logIn_Id): Observable<any> {
  return this.http.get(`${this.baseUrl}/CheckStudentUserName?id=${logIn_Id}`)
}

  // --- update student admission -----
  GetSearchFor(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSearchFor?id=${EditAdmission.searchBy}&sessionId=${EditAdmission.sessionId}&courseId=${EditAdmission.courseId}&branchId=${EditAdmission.branchId}&yearId=${EditAdmission.yearId}&semesterId=${EditAdmission.semesterId}`, EditAdmission)
  }

  SearchStudent(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/searchStudent?id=${EditAdmission.searchFor}`, EditAdmission)
  }

  getStudentAdmissionFeeDetails(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentAdmissionFeeDetails?id=${EditAdmission.admissionNo}`, EditAdmission)
  }

  GetQualificationDetails(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQualificationDetails?id=${EditAdmission.studentId}`, EditAdmission)
  }

  GetUploadedDocumentDetails(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUploadedDocumentDetails?id=${EditAdmission.studentId}`, EditAdmission)
  }

  GetSavedDocumentDetails(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSavedDocumentDetails?id=${EditAdmission.studentId}`, EditAdmission)
  }

  GetSavedLanguageDetails(EditAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSavedLanguageDetails?id=${EditAdmission.studentId}`, EditAdmission)
  }

  UpdateStudentNewAdmission(EditAdmission): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateStudentNewAdmission`, EditAdmission)
  }

  // --- student Registration -----
  GetStudentRegistrationData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentRegistrationData`)
  }

  checkFormnumber(studentRegistration): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkFormnumber?id=${studentRegistration.formNo}`, studentRegistration)
  }
  getStudentRegistrationByForm(studentRegistration): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentRegistrationByForm?id=${studentRegistration.appFormNo}`, studentRegistration)
  }

  SaveStudentRegistration(studentRegistration): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentRegistration`, studentRegistration)
  }

  UpdateStudentRegistration(studentRegistration): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateStudentRegistration`, studentRegistration)
  }

  DeleteStudentRegistration(studentRegistration): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteStudentRegistration?id=${studentRegistration.id}`)
  }

  // ----------- Cancel Student Admission-------
  GetStudentForCancel(CancelAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForCancel?id=${CancelAdmission.sessionId}&courseId=${CancelAdmission.courseId}&branchId=${CancelAdmission.branchId}&yearId=${CancelAdmission.yearId}&semesterId=${CancelAdmission.semesterId}`, CancelAdmission)
  }

  GetStudentDetailForCancel(CancelAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentDetailForCancel?id=${CancelAdmission.admissionNo}`, CancelAdmission)
  }

  SaveCancelStudAdmission(CancelAdmission): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCancelStudAdmission`, CancelAdmission)
  }

  GetStudentFeeMaster(StudentFee): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentFeeMaster?id=${StudentFee.admissionNo}`, StudentFee)
  }

  SaveFeeData(StudentFee): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveFeeData`, StudentFee)
  }

  GetFeeReceiptData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeReceiptData`)
  }

  GetStudentReceiptTrans(StudentReceiptTrans): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentReceiptTrans?id=${StudentReceiptTrans.receiptNo}`, StudentReceiptTrans)
  }

  SaveRollNo(RollNoAllotment): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveRollNo`, RollNoAllotment)
  }

  GetStudentiRollNo(RollNoAllotment): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentiRollNo?sessionId=${RollNoAllotment.sessionId}&courseId=${RollNoAllotment.courseId}&branchId=${RollNoAllotment.branchId}&yearId=${RollNoAllotment.yearId}&semesterId=${RollNoAllotment.semesterId}&arrangeStudentBy=${RollNoAllotment.arrangeStudentBy}`)
  }

  GetStudentForRollNo(ChangeRollNo): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForRollNo?id=${ChangeRollNo.SessionId}&CourseId=${ChangeRollNo.CourseId}&BranchId=${ChangeRollNo.BranchId}&YearId=${ChangeRollNo.YearId}&SemesterId=${ChangeRollNo.SemesterId}&ArrangeStudentBy=${ChangeRollNo.ArrangeStudentBy}`, ChangeRollNo)
  }

  // ---------------student batch ----------
  GetBatchDetails(StudentBatch): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBatchDetails?sessionId=${StudentBatch.sessionId}&courseId=${StudentBatch.courseId}&branchId=${StudentBatch.branchId}&yearId=${StudentBatch.yearId}&semesterId=${StudentBatch.semesterId}`)
  }

  // GetStudentForSaveBatch(StudentBatch): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/getStudentForSaveBatch?id=${StudentBatch.sessionId}&CourseId=${StudentBatch.CourseId}&BranchId=${StudentBatch.BranchId}&YearId=${StudentBatch.YearId}&SemesterId=${StudentBatch.SemesterId}`, StudentBatch)
  // }

  SaveStudentBatch(StudentBatch): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentBatch`, StudentBatch)
  }

  
  // GetStudentForSaveBatch(StudentBatch): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/getStudentForSaveBatch?id=${StudentBatch.sessionId}&CourseId=${StudentBatch.CourseId}&BranchId=${StudentBatch.BranchId}&YearId=${StudentBatch.YearId}&SemesterId=${StudentBatch.SemesterId}`, StudentBatch)
  // }

  GetStudentForSaveBatch(StudentBatch): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForSaveBatch?sessionId=${StudentBatch.sessionId}&courseId=${StudentBatch.courseId}&branchId=${StudentBatch.branchId}&yearId=${StudentBatch.yearId}&semesterId=${StudentBatch.semesterId}`)
  }

  GetStudentForBatch(StudentBatch): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForBatch?sessionId=${StudentBatch.sessionId}&courseId=${StudentBatch.courseId}&branchId=${StudentBatch.branchId}&yearId=${StudentBatch.yearId}&semesterId=${StudentBatch.semesterId}&batchId=${StudentBatch.batchId}`)
  }

  
  // GetStudentForBatch(StudentBatch): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/getStudentForBatch?id=${StudentBatch.SessionId}&CourseId=${StudentBatch.CourseId}&BranchId=${StudentBatch.BranchId}&YearId=${StudentBatch.YearId}&SemesterId=${StudentBatch.SemesterId}&BatchId=${StudentBatch.BatchId}`, StudentBatch)
  // }

  UpdateStudentBatch(StudentBatch): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateStudentBatch`, StudentBatch)
  }

  // ----------------------------- Posting Time Table -----------------------------

  SavePostingTT(PostingTT): Observable<any> {
    return this.http.post(`${this.baseUrl}/savePostingTT`, PostingTT)
  }

  GetPostingTT(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPostingTT`)
  }

  GetPostingTTTrans(PostingTT): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPostingTTTrans?id=${PostingTT.postingTTM_Id}`)
  }


  UpdatePostingTT(PostingTT): Observable<any> {
    return this.http.put(`${this.baseUrl}/updatePostingTT?id=${PostingTT.postingTTM_Id}`, PostingTT)
  }

  DeletePostingTT(PostingTT): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deletePostingTT?id=${PostingTT.postingTTM_Id}`)
  }

  // --------------------- Generate Time Table   ------------------
  GeneratePostingTT(GTimeTable): Observable<any> {
    return this.http.get(`${this.baseUrl}/generatePostingTT?id=${GTimeTable.sessionId}&CourseId=${GTimeTable.courseId}&BranchId=${GTimeTable.branchId}&YearId=${GTimeTable.yearId}&SemesterId=${GTimeTable.semesterId}`)
  }

  // ---------------------- Student Upgrade New -----------------
  GetStudentForUpgrade(StudentUpgrade): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForUpgrade?id=${StudentUpgrade.sessionId}&courseId=${StudentUpgrade.courseId}&branchId=${StudentUpgrade.branchId}&yearId=${StudentUpgrade.yearId}&semesterId=${StudentUpgrade.semesterId}`, StudentUpgrade)
  } 
  GetStudentForMarksheet(StudentUpgrade): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetStudentForMarksheet?id=${StudentUpgrade.SessionId}&CourseId=${StudentUpgrade.CourseId}&BranchId=${StudentUpgrade.BranchId}&YearId=${StudentUpgrade.YearId}&SemesterId=${StudentUpgrade.SemesterId}`, StudentUpgrade)
  }

  SaveStudentUpgrade(StudentUpgrade): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentUpgrade`, StudentUpgrade)
  }
  // ------------------------------ Student Upgrde Fee -----------------
  GetStudentForUpgradeFee(StudentUpgradeFee): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForUpgradeFee?id=${StudentUpgradeFee.sessionId}&courseId=${StudentUpgradeFee.courseId}&branchId=${StudentUpgradeFee.branchId}&yearId=${StudentUpgradeFee.yearId}`, StudentUpgradeFee)
  }

  SaveUpgradeStudentFee(StudentUpgradeFee): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveUpgradeStudentFee`, StudentUpgradeFee)
  }

  // ------------------------------ Student Assignment ---------------------------------
  GetStudAssignment(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudAssignment`)
  }

  SaveStudentAssignment(StudentAssignment): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentAssignment`, StudentAssignment)
  }


  GetBatchForTT(GenerateTT): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBatchForTT?id=${GenerateTT.FromDateTT}&ToDateTT=${GenerateTT.ToDateTT}&SubjectTT=${GenerateTT.SubjectTT}`, GenerateTT)
  }

  // ---------------------- Lecture Time Table -------------------------------------
  GetTimeBySem(LectureTT): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTimeBySem?id=${LectureTT.courseId}&BranchId=${LectureTT.branchId}&YearId=${LectureTT.yearId}&SemesterId=${LectureTT.semesterId}`)
  }


  SaveLectureTT(LectureTT): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLectureTT`, LectureTT)
  }

  GetLectureTT(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLectureTT`)
  }

  GetLectureTTTrans(LectureTT): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLectureTTTrans?id=${LectureTT.lectureTTId}`)
  }

  UpdateLectureTT(LectureTT): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLectureTT?id=${LectureTT.lectureTTId}`, LectureTT)
  }

  DeleteLectureTT(LectureTT): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLectureTT?id=${LectureTT.lectureTTId}`)
  }

  // ------------------------------- Generate Lecture Time Table------------------
  GenerateLectureTT(GLectureTimeTable): Observable<any> {
    return this.http.get(`${this.baseUrl}/generateLectureTT?id=${GLectureTimeTable.sessionId}&CourseId=${GLectureTimeTable.courseId}&BranchId=${GLectureTimeTable.branchId}&YearId=${GLectureTimeTable.yearId}&SemesterId=${GLectureTimeTable.semesterId}`)
  }


  // --------------------- student attendance -------------------------------------------
  GetSubjectByType(StudentAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubjectByType?id=${StudentAttendance.sessionId}&courseId=${StudentAttendance.courseId}&branchId=${StudentAttendance.branchId}&yearId=${StudentAttendance.yearId}&semesterId=${StudentAttendance.semesterId}&dayId=${StudentAttendance.dayId}&type=${StudentAttendance.type}`, StudentAttendance)
  }

  GetStudentForAttendance(StudentAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForAttendance?id=${StudentAttendance.sessionId}&courseId=${StudentAttendance.courseId}&branchId=${StudentAttendance.branchId}&yearId=${StudentAttendance.yearId}&semesterId=${StudentAttendance.semesterId}&attendanceDate=${StudentAttendance.attendanceDate}&main_Type=${StudentAttendance.main_Type}&type=${StudentAttendance.type}&subjectId=${StudentAttendance.subjectId}`, StudentAttendance)
  }

  SaveStudentAttendance(StudentAttendance): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentAttendance`, StudentAttendance)
  }

  SearchStudentAttendance(StudentAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/searchStudentAttendance?id=${StudentAttendance.sessionId}&courseId=${StudentAttendance.courseId}&branchId=${StudentAttendance.branchId}&yearId=${StudentAttendance.yearId}&semesterId=${StudentAttendance.semesterId}&subjectId=${StudentAttendance.subjectId}&attendanceDate=${StudentAttendance.attendanceDate}&type=${StudentAttendance.type}&main_Type=${StudentAttendance.main_Type}`, StudentAttendance)
  }

  UpdateStudentAttendance(StudentAttendance): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateStudentAttendance?id=${StudentAttendance.attendanceId}`, StudentAttendance)

  }


  // ---------------front desk ----------
  GetStudentForCharacter(CancelAdmission): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForCharacter?id=${CancelAdmission.sessionId}&courseId=${CancelAdmission.courseId}&branchId=${CancelAdmission.branchId}&yearId=${CancelAdmission.yearId}&semesterId=${CancelAdmission.semesterId}`)
  }
  SaveCharacterCertificate(Certificate): Observable<any> {
    return this.http.post(`${this.baseUrl}/savecharactercertificate`, Certificate)
  }

  UpdateCharacterCertificate(Certificate): Observable<any> {
    return this.http.put(`${this.baseUrl}/updatecharactercertificate`, Certificate)
  }

  SaveNoticeFormat(Notice): Observable<any> {
    return this.http.post(`${this.baseUrl}/savenoticeformat`, Notice)
  }

  SaveInwardLetter(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveinwardletter`, Letter)
  }

  UpdateInwardLetter(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateinwardletter`, Letter)
  }

  DeleteInwardLetter(srNo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteinwardletter?srNo=${srNo}`)
  }

  SaveOutwardLetter(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveoutwardletter`, Letter)
  }

  UpdateOutwardLetter(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateoutwardletter`, Letter)
  }

  DeleteOutwardLetter(SrNo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteoutwardletter?SrNo=${SrNo}`)
  }

  GetCharacterCertificate(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getcharactercertificate?schoolId=${schoolId}`)
  }

  GetNoticeFormat(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getnoticeformat?SchoolId=${schoolId}`)
  }

  GetInwardLetter(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getinwardletter?schoolId=${schoolId}`)
  }

  GetOutwardLetter(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getoutwardletter?schoolId=${schoolId}`)
  }

  GetStudentsforNotice(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/getgtudentsfornotice?id=${data.sessionId}&courseId=${data.courseId}&branchId=${data.branchId}&yearId=${data.yearId}`)
  }


  // ---------------------------- Master Employee ---------------------
  // EmployeeDesignation ROUTING
  SaveEmployeeDesignation(EmployeeDesignation): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveEmployeeDesignation`, EmployeeDesignation)
  }
  getEmployeeDesignation(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeDesignation`)
  }
  UpdateEmployeeDesignation(EmployeeDesignation): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEmployeeDesignation?id=${EmployeeDesignation.designationId}`, EmployeeDesignation)
  }
  DeleteEmployeeDesignation(EmployeeDesignation): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteEmployeeDesignation?id=${EmployeeDesignation.designationId}`)
  }
  // LeaveTypeMaster ROUTING
  SaveLeaveTypeMaster(LeaveTypeMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveLeaveTypeMaster`, LeaveTypeMaster)
  }
  getLeaveTypeMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLeaveTypeMaster`)
  }
  UpdateLeaveTypeMaster(LeaveTypeMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLeaveTypeMaster?id=${LeaveTypeMaster.leaveTypeId}`, LeaveTypeMaster)
  }
  DeleteLeaveTypeMaster(LeaveTypeMaster): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLeaveTypeMaster?id=${LeaveTypeMaster.leaveTypeId}`)
  }
  // DepartmentMaster ROUTING
  SaveDepartmentMaster(DepartmentMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveDepartmentMaster`, DepartmentMaster)
  }
  getDepartmentMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDepartmentMaster`)
  }
  UpdateDepartmentMaster(DepartmentMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateDepartmentMaster?id=${DepartmentMaster.departmentId}`, DepartmentMaster)
  }
  DeleteDepartmentMaster(DepartmentMaster): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteDepartmentMaster?id=${DepartmentMaster.departmentId}`)
  }
  // SalaryHeadMaster ROUTING
  SaveSalaryHeadMaster(SalaryHeadMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveSalaryHeadMaster`, SalaryHeadMaster)
  }
  getSalaryHeadMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSalaryHeadMaster`)
  }
  UpdateSalaryHeadMaster(SalaryHeadMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateSalaryHeadMaster?id=${SalaryHeadMaster.id}`, SalaryHeadMaster)
  }
  DeleteSalaryHeadMaster(SalaryHeadMaster): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteSalaryHeadMaster?id=${SalaryHeadMaster.id}`, SalaryHeadMaster)
  }
  // GradeMaster ROUTING
  SaveGradeMaster(GradeMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveGradeMaster`, GradeMaster)
  }
  getGradeMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getGradeMaster`)
  }
  UpdateGradeMaster(GradeMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateGradeMaster?id=${GradeMaster.gradeId}`, GradeMaster)
  }
  DeleteGradeMaster(GradeMaster): Observable<any> {

    return this.http.delete(`${this.baseUrl}/deleteGradeMaster?id=${GradeMaster.gradeId}`)
  }
  // LeaveAllotmentMaster ROUTING
  SaveLeaveAllotmentMaster(LeaveAllotmentMaster): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveLeaveAllotmentMaster`, LeaveAllotmentMaster)
  }
  getLeaveAllotmentMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLeaveAllotmentMaster`)
  }
  UpdateLeaveAllotmentMaster(LeaveAllotmentMaster): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLeaveAllotmentMaster?id=${LeaveAllotmentMaster.allotmentId}`, LeaveAllotmentMaster)
  }
  DeleteLeaveAllotmentMaster(LeaveAllotmentMaster): Observable<any> {

    return this.http.delete(`${this.baseUrl}/deleteLeaveAllotmentMaster?id=${LeaveAllotmentMaster.allotmentId}`)
  }

  // EmployeeDetails ROUTING
  CheckEmployeeUserName(userName): Observable<any> {
    return this.http.get(`${this.baseUrl}/CheckEmployeeUserName?Username=${userName}`)
  }

  SaveEmployeeDetails(EmployeeDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveEmployeeDetails`, EmployeeDetails)
  }
  getEmployeeDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeDetails`)
  }
  UpdateEmployeeDetails(EmployeeDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEmployeeDetails?id=${EmployeeDetails.employeeId}`, EmployeeDetails)
  }
  DeleteEmployeeDetails(EmployeeDetails): Observable<any> {

    return this.http.delete(`${this.baseUrl}/deleteEmployeeDetails?id=${EmployeeDetails.EmployeeId}`, EmployeeDetails)
  }
  // GetCityByDistrict(District): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/GetCityByDistrict?id=${District.DistrictId}`, District)
  // }
  getApprovalCCBy(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getApprovalCCBy`)
  }
  getApprovalBy(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getApprovalBy`)
  }
  GetAllUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAllUsers`)
  }
  getAllEmployeeDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllEmployeeDetails`)
  }
  GetAllowanceDeduction(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAllowanceDeduction`)
  }
  getCertificates(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCertificates`)
  }
  getMultipleDataOfEmployee(employeeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMultipleDataOfEmployee?id=${employeeId}`)
  }

  GetHolidayType(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetHolidayType`)
  }

  GetEmployeesForAttandanceDetails(EmployeesForAttandance): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetEmployeesForAttandanceDetails?DepartmentId=${EmployeesForAttandance.departmentId}&&Date_Of_Attendance=${EmployeesForAttandance.date_Of_Attendance}&&EmployeeType=${EmployeesForAttandance.employeeType}&&IsTeacher=${EmployeesForAttandance.isTeacher}`)
  }
  SaveEmployeesAttandance(EmployeeAttandance): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveEmployeesAttandance`, EmployeeAttandance)
  }

  GetEmployeesSavedAttandance(EmployeesForAttandance): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetEmployeesSavedAttandance?DepartmentId=${EmployeesForAttandance.departmentId}&&Date_Of_Attendance=${EmployeesForAttandance.date_Of_Attendance}&&EmployeeType=${EmployeesForAttandance.employeeType}`)
  }
  GetYearList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetYearList`)
  }
  SearchAttendance(temp): Observable<any> {
    return this.http.get(`${this.baseUrl}/SearchAttendance?EmployeeId=${temp.employeeId}&&fromDate=${temp.fromDate}&&toDate=${temp.toDate}&&Date=${temp.date}`)
  }

  GetAllEmployees(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAllEmployees`)
  }
  GetLeaveAuthorityEmployee(user_id): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetLeaveAuthorityEmployee?id=${user_id}`)
  }
  
  UpdateEmployeesAttandance(EmployeeDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateEmployeesAttandance?id=${EmployeeDetails.attendance_Id}`, EmployeeDetails)
  }

  // crud EmplpoyeeSalary


  generateSalary(temp): Observable<any> {
    return this.http.get(`${this.baseUrl}/generateSalary?salaryYear=${temp.salaryYear}&&fromDate=${temp.fromDate}&&toDate=${temp.toDate}&&month=${temp.month}&&empType=${temp.empType}&&numberOfDays=${temp.numberOfDays}`)
  }

  saveSalaryDetails(salaryDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveSalaryDetails`, salaryDetails)
  }

  // crud EmplpoyeePromotion
  GetAllEmployeesByEmployeeType(employeeType): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAllEmployeesByEmployeeType?id=${employeeType}`)
  }
  GetEmployeePromotionDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetEmployeePromotionDetails`)
  }
  savePromotionDetails(PromotionDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/savePromotionDetails`, PromotionDetails)
  }
  getAllowanceDeductionDetails(employeeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllowanceDeductionDetails?id=${employeeId}`)
  }
  updateEmployeePromotionDetails(PromotionDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEmployeePromotionDetails?id=${PromotionDetails.promotionId}`, PromotionDetails)
  }

  // crud EmplpoyeeLEAVEAPPLICATION
  getTotalLeave(EmployeeId, LeaveTypeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTotalLeave?id=${EmployeeId}&&LeaveTypeId=${LeaveTypeId}`)

  }

  getHolidaysOfEmployees(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHolidaysOfEmployees`)
  }
  getHolidaysOfEmployeesCount(fromDate, toDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHolidaysOfEmployeesCount?fromDate=${fromDate}&&toDate=${toDate}`)
  }

  getEmployeeLeaveApplication(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeLeaveApplication`)
  }
  saveEmployeeLeaveApplication(LeaveApplicationDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveEmployeeLeaveApplication`, LeaveApplicationDetails)
  }
  getUpdateTotalBalanceLeaves(EmployeeId, LeaveTypeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUpdateTotalBalanceLeaves?id=${EmployeeId}&&LeaveTypeId=${LeaveTypeId}`)
  }
  updateEmployeeLeaveApplication(LeaveApplicationDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEmployeeLeaveApplication?id=${LeaveApplicationDetails.appNo}`, LeaveApplicationDetails)
  }

  // crud EmplpoyeeLEAVESanction
  getAllEmployeeLeaveApplicationForSanction(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllEmployeeLeaveApplicationForSanction`)
  }

  getEmployeeLeaveApplicationForSanction(EmployeeId, LeaveFrom, LeaveTo, Status): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeLeaveApplicationForSanction?id=${EmployeeId}&&LeaveFrom=${LeaveFrom}&&LeaveTo=${LeaveTo}&&Status=${Status}`)
  }
  AcceptEmployeeLeaveSanction(EmployeeLeaveSanction): Observable<any> {
    return this.http.post(`${this.baseUrl}/AcceptEmployeeLeaveSanction`, EmployeeLeaveSanction)
  }
  RejectEmployeeLeaveSanction(EmployeeLeaveSanction): Observable<any> {
    return this.http.post(`${this.baseUrl}/RejectEmployeeLeaveSanction`, EmployeeLeaveSanction)
  }
  updateLeaveApplicationAtSanction(EmployeeLeaveSanction): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateLeaveApplicationAtSanction`, EmployeeLeaveSanction)
  }

  // crud EmplpoyeeResignation
  getDepartmentDesignationWiseUsers(DepartmentId, DesignationId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDepartmentDesignationWiseUsers?DepartmentId=${DepartmentId}&&DesignationId=${DesignationId}`)
  }
  checkAlreadyPendingResignation(employeeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkAlreadyPendingResignation?employee_id=${employeeId}`)
  }
  saveResignationDetails(EmployeeResignation): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveResignationDetails`, EmployeeResignation)
  }
  updateResignationDetails(EmployeeResignation): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateResignationDetails`, EmployeeResignation)
  }

  getResignationDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getResignationDetails`)
  }


  // crud EmplpoyeeResignationSanction
  getEmployeeResignationApplicationForSanction(DepartmentId, DesignationId, fromDate, toDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeResignationApplicationForSanction?DepartmentId=${DepartmentId}&&fromDate=${fromDate}&&toDate=${toDate}&&DesignationId=${DesignationId}`)
  }

  getAllEmployeeResignationApplicationForSanction(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllEmployeeResignationApplicationForSanction`)
  }

  RejectEmployeeResignationSanction(EmployeeResignation): Observable<any> {
    return this.http.post(`${this.baseUrl}/RejectEmployeeResignationSanction`, EmployeeResignation)
  }
  AcceptEmployeeResignationSanction(EmployeeResignation): Observable<any> {
    return this.http.post(`${this.baseUrl}/AcceptEmployeeResignationSanction`, EmployeeResignation)
  }


  // crud MasterRole
  saveMasterRole(MasterRoleDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterRole`, MasterRoleDetails)
  }
  getMasterRole(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterRole`)
  }

  updateMasterRole(MasterRoleDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterRole?id=${MasterRoleDetails.roleId}`, MasterRoleDetails)
  }
  deleteMasterRole(roleId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterRole?id=${roleId}`)
  }


  // crud MasterUser
  saveMasterUsers(MasterUserDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterUsers`, MasterUserDetails)
  }
//when college add create admin
saveAdminForClg(MasterUserDetails): Observable<any> {
  return this.http.post(`${this.baseUrl}/saveAdminForClg`, MasterUserDetails)
}

  getMasterUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterUsers`)
  }
  updateMasterUsers(MasterUserDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterUsers?id=${MasterUserDetails.userCode}`, MasterUserDetails)
  }
  deleteMasterUsers(UserId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterUsers?id=${UserId}`)
  }
  changeUserPassword(UserCode, MasterUserDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/changeUserPassword?id=${UserCode}`, MasterUserDetails)
  }

  // UserAuthentication CRUD

  SaveUserAuthentication(UserAuthenticationDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveUserAuthentication`, UserAuthenticationDetails)
  }
  UpdateUserAuthentication(UserAuthenticationDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateUserAuthentication?id=${UserAuthenticationDetails.UserCode}`, UserAuthenticationDetails)
  }
  DeleteUserAuthentication(UserCode): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteUserAuthentication?id=${UserCode}`)
  }

  getUrl(user_id): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUrl?user_id=${user_id}`);
  }

  SaveBookCategory(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savebookcategory`, Letter)
  }

  updatebookcategory(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/updatebookcategory`, Letter)
  }

  DeleteBookCategory(bookCategoryId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deletebookcategory?id=${bookCategoryId}`)
  }

  GetBookCategory(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getbookcategory?id=${schoolId}`)
  }
  SaveFine(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savefine`, Letter)
  }
  UpdateFine(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateFine?id=${Letter.fineId}`, Letter)
  }

  GetFine(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getfine?SchoolId=${schoolId}`)
  }

  SaveIssueDay(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveissueday`, Letter)
  }

  UpdateIssueDay(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateIssueDay`, Letter)
  }

  GetIssueDay(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getissueday?id=${schoolId}`)
  }

  SaveIssueDayCounter(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveissuedaycounter`, Letter)
  }

  UpdateIssueDayCounter(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateIssueDayCounter`, Letter)
  }

  GetIssueDayCounter(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getissuedaycounter?id=${schoolId}`)
  }

  SaveReIssueDayCounter(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savereissuedaycounter`, Letter)
  }

  UpdateReIssueDayCounter(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateReIssueDayCounter?id=${Letter.IssueCounterId}`, Letter)
  }

  GetReIssueDayCounter(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getreissuedaycounter?SchoolId=${schoolId}`)
  }

  SaveReIssueDay(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savereissueday`, Letter)
  }

  UpdateReIssueDay(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateReIssueDay?id=${Letter.reIssueDayId}`, Letter)
  }

  GetReIssueDay(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getreissueday?id=${schoolId}`)
  }

  // ---------------- Master Hostel ---------------------------------

  SaveHostelMaster(MasterHostel): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelMaster`, MasterHostel)
  }

  GetHostelMaster(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelMaster`)
  }

  UpdateHostelMaster(MasterHostel): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelMaster?id=${MasterHostel.hostelId}`, MasterHostel)
  }

  DeleteHostelMaster(MasterHostel): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelMaster?id=${MasterHostel.hostelId}`)
  }

  // ---------------- Hostel In Time Master ------------------
  SaveHostelIntime(HostelInTime): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelIntime`, HostelInTime)
  }

  GetHostelInTimeDetails(HostelInTime): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelInTimeDetails?id=${HostelInTime.schoolId}`)
  }

  UpdateHostelIntime(HostelInTime): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelIntime?id=${HostelInTime.schoolId}`, HostelInTime)
  }

  // -------------------------------- Hostel Room ------------------------------------
  SaveHostelRoom(HostelRoom): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelRoom`, HostelRoom)
  }

  GetHostelRoom(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelRoom`)
  }

  UpdateHostelRoom(HostelRoom): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelRoom?id=${HostelRoom.roomId}`, HostelRoom)
  }

  DeleteHostelRoom(HostelRoom): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelRoom?id=${HostelRoom.RoomId}`, HostelRoom)
  }

  // -------------------- Hostel bed -------------
  GetFloorNo(HostelBed): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFloorNo?id=${HostelBed.hostelId}`, HostelBed)
  }

  GetRoomNo(HostelBed): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRoomNo?id=${HostelBed.hostelId}&&floorNo=${HostelBed.floorNo}`, HostelBed)
  }

  SaveHostelBed(HostelBed): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelBed`, HostelBed)
  }

  GetHostelBed(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelBed`)
  }

  UpdateHostelBed(HostelBed): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelBed?id=${HostelBed.hostel_Bed_Id}`, HostelBed)
  }

  DeleteHostelBed(HostelBed): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelBed?id=${HostelBed.Hostel_Bed_Id}`, HostelBed)
  }

  // ------------------------------------ Room Allocation -----------------------------

  GetStudentForRoomAlloc(RoomAllocation): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForRoomAlloc?id=${RoomAllocation.sessionId}&courseId=${RoomAllocation.courseId}&branchId=${RoomAllocation.branchId}&year=${RoomAllocation.year}`, RoomAllocation)
  }

  GetRoomNoByHostel(RoomAllocation): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRoomNoByHostel?id=${RoomAllocation.hostelId}`, RoomAllocation)
  }

  GetBedNoByHostel(RoomAllocation): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBedNoByHostel?id=${RoomAllocation.hostelId}&&roomNo=${RoomAllocation.roomNo}`, RoomAllocation)
  }

  GetStudentForRoomAllocUpdate(RoomAllocation): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForRoomAllocUpdate?id=${RoomAllocation.sessionId}&courseId=${RoomAllocation.courseId}&branchId=${RoomAllocation.branchId}&year=${RoomAllocation.year}`, RoomAllocation)
  }

  GetBedNoByHostelUpdate(RoomAllocation): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBedNoByHostelUpdate?id=${RoomAllocation.hostelId}&&roomNo=${RoomAllocation.roomNo}`, RoomAllocation)
  }

  SaveRoomAllocation(RoomAllocation): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveRoomAllocation`, RoomAllocation)
  }

  GetRoomAllocation(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRoomAllocation`)
  }

  UpdateRoomAllocation(RoomAllocation): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateRoomAllocation?id=${RoomAllocation.AllocationId}`, RoomAllocation)
  }

  DeleteRoomAllocation(RoomAllocation): Observable<any> {
    return this.http.put(`${this.baseUrl}/deleteRoomAllocation?id=${RoomAllocation.AllocationId}`, RoomAllocation)
  }
  //  ------------------------------------- Room Transfer --------------------------------
  GetStudentForRoomTransfer(RoomTransfer): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForRoomTransfer?id=${RoomTransfer.sessionId}&courseId=${RoomTransfer.courseId}&branchId=${RoomTransfer.branchId}&year=${RoomTransfer.year}&hostelId=${RoomTransfer.hostelId}`, RoomTransfer)
  }

  SaveRoomTransfer(RoomTransfer): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveRoomTransfer`, RoomTransfer)
  }

  // ------------------------------------------ Hostel Attendance -------------------------
  GetStudentsOfHostel(HostelAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentsOfHostel?id=${HostelAttendance.sessionId}&hostelId=${HostelAttendance.hostelId}`, HostelAttendance)
  }

  GetStudentsOfHostelAtt(HostelAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentsOfHostelAtt?id=${HostelAttendance.sessionId}&hostelId=${HostelAttendance.hostelId}&attendanceDate=${HostelAttendance.attendanceDate}`, HostelAttendance)
  }

  SaveHostelAttendance(HostelAttendance): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelAttendance`, HostelAttendance)
  }

  GetSavedHostelAttend(HostelAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSavedHostelAttend?id=${HostelAttendance.sessionId}&hostelId=${HostelAttendance.hostelId}&attendanceDate=${HostelAttendance.attendanceDate}`, HostelAttendance)
  }

  UpdateHostelAttendance(HostelAttendance): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelAttendance?id=${HostelAttendance.HostelAttendId}`, HostelAttendance)

  }

  GetHostelAttendance(HostelAttendance): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelAttendance?id=${HostelAttendance.studentId}&attendanceBy=${HostelAttendance.attendanceBy}&year=${HostelAttendance.year}&month=${HostelAttendance.month}&attendanceDate=${HostelAttendance.attendanceDate}`, HostelAttendance)
  }

  // -------------------------------- hostel Visitors-------------------------------------------
  GetHostelStudents(HostelVisitors): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelStudents?id=${HostelVisitors.hostelId}`, HostelVisitors)
  }


  SaveHostelVisitors(HostelVisitors): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelVisitors`, HostelVisitors)
  }

  GetVisitorData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVisitorData`)
  }

  UpdateHostelVisitors(HostelVisitors): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelVisitors?id=${HostelVisitors.visitId}`, HostelVisitors)
  }

  DeleteHostelVisitors(HostelVisitors): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelVisitors?id=${HostelVisitors.VisitId}`, HostelVisitors)
  }

  // ------------------------- Hostel Expenses ------------------------------
  SaveHostelExpenses(HostelExpenses): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelExpenses`, HostelExpenses)
  }

  GetHostelExpense(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelExpense`)
  }

  UpdateHostelExpenses(HostelExpenses): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelExpenses?id=${HostelExpenses.expenceId}`, HostelExpenses)
  }

  DeleteHostelExpenses(HostelExpenses): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelExpenses?id=${HostelExpenses.ExpenceId}`, HostelExpenses)
  }

  // --------------------------- Hostel Losses --------------------------------------
  SaveHostelLosses(HostelLosses): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelLosses`, HostelLosses)
  }

  GetHostelLosses(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelLosses`)
  }

  GetHostelStudentsLosses(HostelLosses): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelStudentsLosses?id=${HostelLosses.lossesCommon_Id}`, HostelLosses)
  }

  UpdateHostelLosses(HostelLosses): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateHostelLosses?id=${HostelLosses.lossesCommon_Id}`, HostelLosses)
  }

  DeleteHostelLosses(HostelLosses): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteHostelLosses?id=${HostelLosses.lossesCommon_Id}`, HostelLosses)
  }

  // ------------------------ Hostel Leaving -----------------------------------

  GetStudentForLeave(HostelLeaving): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForLeave?id=${HostelLeaving.sessionId}&hostelId=${HostelLeaving.hostelId}&hostelRoomId=${HostelLeaving.hostelRoomId}`, HostelLeaving)
  }

  SaveHostelLeaving(HostelLeaving): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveHostelLeaving`, HostelLeaving)
  }

  // ------------------------------ Hostel OutPass ----------------------
  GetStudentForOutPass(HostelOutPass): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForOutPass?id=${HostelOutPass.hostelId}&hostelRoom_Id=${HostelOutPass.hostelRoom_Id}`, HostelOutPass)
  }

  SendOutPassRequest(HostelOutPass): Observable<any> {
    return this.http.post(`${this.baseUrl}/sendOutPassRequest`, HostelOutPass)
  }

  GetHostelOutPass(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelOutPass`)
  }

  // ------------------------------- Hostel Accept Outpass --------------------
  GetHostelAcceptOutPass(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelAcceptOutPass`)
  }

  UpdateOutPassRequest(AcceptOutPass): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateOutPassRequest?id=${AcceptOutPass.outpassId}`, AcceptOutPass)
  }

  // ------------------------- Login Form ------------------
  LoginUser(Login): Observable<any> {
    return this.http.post(`${this.baseUrl}/LoginUser?id=${Login.UserName}&Password=${Login.Password1}`, Login)
  }

  getPrivileges(Auth): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPrivileges?id=${Auth.User_Code}&Token=${Auth.Token}`, Auth)
  }

  LoginStudent(Login): Observable<any> {
    return this.http.post(`${this.baseUrl}/LoginStudent?id=${Login.UserName}&Password=${Login.Password}`, Login)
  }

  // -------------- Master Lab -----------------------------------------------
  GetLab(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLab`)
  }

  SaveLab(MasterLab): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLab`, MasterLab)
  }

  UpdateLab(MasterLab): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLab?id=${MasterLab.labId}`, MasterLab)
  }

  DeleteLab(MasterLab): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLab?id=${MasterLab.labId}`)
  }

  // ------------- Save Lab Requisition -----------------------------------------
  SavelabReuisition(labReuisition): Observable<any> {
    return this.http.post(`${this.baseUrl}/savelabReuisition`, labReuisition)
  }
  save(form): Observable<any> {
    return this.http.post(`http://localhost:1114/gameratemaster/jsonValidation`, form)
  }
  GetlabReuisition(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getlabReuisition`)
  }

  GetlabReuisitionTrans(labReuisition): Observable<any> {
    return this.http.get(`${this.baseUrl}/getlabReuisitionTrans?id=${labReuisition.listId}`)
  }

  UpdateLabReuisition(labReuisition): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLabReuisition`, labReuisition)
  }

  DeleteLabReuisition(labReuisition): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLabReuisition?id=${labReuisition.listId}`)
  }

  // -------------- Master Lab Loss-----------------------------------------------
  GetLabLoss(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLabLoss`)
  }

  SaveLabLoss(LabLoss): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLabLoss`, LabLoss)
  }

  UpdateLabLoss(LabLoss): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLabLoss`, LabLoss)
  }

  DeleteLabLoss(LabLoss): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLabLoss?id=${LabLoss.lossId}`)
  }





  SaveBookDetails(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savebookdetails`, Letter)
  }

  UpdateBookDetails(Letter): Observable<any> {
    return this.http.put(`${this.baseUrl}/updatebookdetails`, Letter)
  }

  DeleteBookDetails(bookId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deletebookdetails?id=${bookId}`)
  }

  GetBookDetails(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getbookdetails?id=${schoolId}`)
  }

  SaveJournaldetails(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/savejournaldetails`, Letter)
  }

  UpdateJournaldetails(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/updatejournaldetails`, Letter)
  }

  DeleteJournaldetails(journalId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deletejournaldetails?id=${journalId}`)
  }

  GetJournaldetails(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getjournaldetails?id=${schoolId}`)
  }

  SaveAccessionRegister(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveaccessionregistration`, Letter)
  }

  UpdateAccessionRegister(Letter): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateaccessionregistration`, Letter)
  }

  GetBookDetailsById(bookid): Observable<any> {
    return this.http.get(`${this.baseUrl}/getbookdetailsbyId?bookid=${bookid}`)
  }

  GetAccessionDetails(schoolId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getaccession?SchoolId=${schoolId}`)
  }

  GetAccessionDetailsById(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/getaccessionbyId?id=${id}`)
  }






  //-----------------------------------------LibraryPurchaseOrder Routing

  getBooksTitle(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBooksTitle`)
  }
  getJournalTitle(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getJournalTitle`)
  }

  getVendorDetailsByVendorId(Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVendorDetailsByVendorId?Vendor_ID=${Vendor_ID}`)
  }
  getBookDetailsByBookId(Book_Id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBookDetailsByBookId?Book_Id=${Book_Id}&&Type=${Type}`)
  }
  saveLibraryPurchaseOrder(LibraryPurchaseOrder): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLibraryPurchaseOrder`, LibraryPurchaseOrder)
  }
  getLibraryPurchaseOrder(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrder`)
  }
  getLibraryPurchaseOrderByVendor(Vendor_ID, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrderByVendor?Vendor_ID=${Vendor_ID}&&Type=${Type}`)
  }
  getLibraryPurchaseOrderByPONO(poNo, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrderByPONO?PO_NO=${poNo}&&Type=${Type}`)
  }

  getLibraryPurchaseOrderTrans(poNo, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrderTrans?PO_No=${poNo}&&Type=${Type}`)
  }
  updateLibraryPurchaseOrder(LibraryPurchaseOrder): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateLibraryPurchaseOrder`, LibraryPurchaseOrder)
  }
  deleteLibraryPurchaseOrder(PO_No): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteLibraryPurchaseOrder?PO_No=${PO_No}`)
  }



  //-----------------------------------------LibraryPurchaseOrderInvoice Routing
  getLibraryPurchaseOrderInvoiceByVendor(Vendor_ID, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrderInvoiceByVendor?Vendor_ID=${Vendor_ID}&&Type=${Type}`)
  }
  getLibraryPurchaseOrderInvoiceByPONO(PO_NO, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryPurchaseOrderInvoiceByPONO?PO_NO=${PO_NO}&&Type=${Type}`)
  }
  cancelBookDetailsForLibraryInvoice(Id): Observable<any> {
    return this.http.get(`${this.baseUrl}/cancelBookDetailsForLibraryInvoice?Id=${Id}`)
  }
  // cancelBookDetailsForLibraryInvoice(Id): Observable<any> {
  //   return this.http.delete(`${this.baseUrl}/cancelBookDetailsForLibraryInvoice?Id=${Id}`)
  // }
  saveLibraryPurchaseOrderInvoice(LibraryPurchaseOrderInvoice): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveLibraryPurchaseOrderInvoice`, LibraryPurchaseOrderInvoice)
  }
  checkLibraryInvoiceNo(Invoice_No, Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkLibraryInvoiceNo?Invoice_No=${Invoice_No}&&Vendor_ID=${Vendor_ID}`)
  }

  //-----------------------------------------LibraryAccessionRegister Routing
  getPrintAccesstionNumber(Type, Book_Id): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPrintAccesstionNumber?Book_Id=${Book_Id}&&Type=${Type}`)
  }
  getNextAccessionNumber(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getNextAccessionNumber`)
  }
  getJournalsFromGeneratedInvoices(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getJournalsFromGeneratedInvoices`)
  }
  getBooksFromGeneratedInvoices(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBooksFromGeneratedInvoices`)
  }
  getBookDetailsByBookIdFromInvoice(Book_Id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBookDetailsByBookIdFromInvoice?Book_Id=${Book_Id}&&Type=${Type}`)
  }
  saveAccessionRegister(AccessionRegister): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveAccessionRegister`, AccessionRegister)
  }

  //-----------------------------------------LibraryDirectAccessionRegister Routing
  saveDirectAccessionRegister(AccessionRegister): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveDirectAccessionRegister`, AccessionRegister)
  }

  //-----------------------------------------LibraryEditDirectAccessionRegister Routing
  getAccessionJournalsBooksFromAccessionRegister(Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAccessionJournalsBooksFromAccessionRegister?Type=${Type}`)
  }
  getaccessionDetailsById(Accession_No, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getaccessionDetailsById?Accession_No=${Accession_No}&&Type=${Type}`)
  }
  updateDirectAccessionRegister(AccessionRegister): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateDirectAccessionRegister`, AccessionRegister)
  }
  //-----------------------------------------LibraryOPAC Routing
  searchOPAC(Type, searchName, searchValue): Observable<any> {
    return this.http.get(`${this.baseUrl}/searchOPAC?Type=${Type}&&searchName=${searchName}&&searchValue=${searchValue}`)
  }
  //-----------------------------------------LibraryOPAC Routing
  getStudentForBookTransaction(StudentList): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForBookTransaction?id=${StudentList.sessionId}&CourseId=${StudentList.courseId}&BranchId=${StudentList.branchId}&YearId=${StudentList.yearId}&SemesterId=${StudentList.semesterId}`)
  }
  //-----------------------------------------LibraryBook Transaction Routing
  getIssuedBooksLibraryDetails(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIssuedBooksLibraryDetails?user_id=${user_id}&&Type=${Type}`)
  }
  getAvailableAccessionNo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAvailableAccessionNo`)
  }
  GetIssueDayCountByType(Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetIssueDayCountByType?Type=${Type}`)
  }
  issueLibraryBook(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/issueLibraryBook`, LibraryBookDetails)
  }
  checkBookAvailableByBarcode(Barcode_No): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkBookAvailableByBarcode?Barcode_No=${Barcode_No}`)
  }
  getLibraryFineForUser(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryFineForUser?user_id=${user_id}&&Type=${Type}`)
  }
  getAccessionNoOfIssuedBooks(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAccessionNoOfIssuedBooks?user_id=${user_id}&&Type=${Type}`)
  }
  getReissuedBooksByUser(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getReissuedBooksByUser?user_id=${user_id}&&Type=${Type}`)
  }
  reissueLibraryBook(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/reissueLibraryBook`, LibraryBookDetails)
  }
  returnLibraryBook(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/returnLibraryBook`, LibraryBookDetails)
  }
  getReturnedBooksLibraryDetails(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getReturnedBooksLibraryDetails?user_id=${user_id}&&Type=${Type}`)
  }


  //-----------------------------------------LibraryBook Binding Routing
  getJournalsBooksForBindingByAccessionNo(Type, Accession_No): Observable<any> {
    return this.http.get(`${this.baseUrl}/getJournalsBooksForBindingByAccessionNo?Type=${Type}&&Accession_No=${Accession_No}`)
  }
  getJournalsBooksForBinding(Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getJournalsBooksForBinding?Type=${Type}`)
  }
  issueForBinding(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/issueForBinding`, LibraryBookDetails)
  }
  getIssuedBooksForBindings(Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIssuedBooksForBindings?Type=${Type}`)
  }
  ReturnFromBinding(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/ReturnFromBinding`, LibraryBookDetails)
  }
  //-----------------------------------------LibraryBook Transaction Reference Routing
  getAccessionNoOfReferenceBook(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAccessionNoOfReferenceBook`)
  }
  getIssuedBooksLibraryReferenceDetails(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIssuedBooksLibraryReferenceDetails?user_id=${user_id}&&Type=${Type}`)
  }
  issueLibraryBookReference(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/issueLibraryBookReference`, LibraryBookDetails)
  }
  getIssuedAccessionNoOfReferenceBook(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIssuedAccessionNoOfReferenceBook?user_id=${user_id}&&Type=${Type}`)
  }
  returnLibraryBookReference(LibraryBookDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/returnLibraryBookReference`, LibraryBookDetails)
  }
  // -----------------------------------
  // Front desk module services
  GetStudentBySem(BonafideCertificate): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetStudentBySem?id=${BonafideCertificate.semesterId}`, BonafideCertificate);
  }

  SaveBonafideCertificate(BonafideCertificate): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveBonafideCertificate`, BonafideCertificate);
  }
  GetBonafideCertificate(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetBonafideCertificate`);
  }

  GetStudentForBonafide(BonafideCertificate): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.baseUrl}/getStudentForBonafide?id=${BonafideCertificate.sessionId}&courseId=${BonafideCertificate.courseId}&branchId=${BonafideCertificate.branchId}&yearId=${BonafideCertificate.yearId}&semesterId=${BonafideCertificate.semesterId}`);
  }


  UpdateBonafideCertificate(BonafideCertificate): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateBonafideCertificate`, BonafideCertificate);
  }


  // Leaving Certificate CRUD

  getStudentByBranch(LeavingCertificateDetails): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentByBranch?id=${LeavingCertificateDetails.sessionId}&&courseId=${LeavingCertificateDetails.courseId}&&branchId=${LeavingCertificateDetails.branchId}&&yearId=${LeavingCertificateDetails.yearId}&&checkUpdate=${LeavingCertificateDetails.ForUpdate}`);
  }
  getStudentByBranchEvent(LeavingCertificateDetails): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentByBranchEvent?sessionId=${LeavingCertificateDetails.sessionId}&&courseId=${LeavingCertificateDetails.courseId}&&branchId=${LeavingCertificateDetails.branchId}&&yearId=${LeavingCertificateDetails.year}`);
  }

  getStudentByBranchEvent1(LeavingCertificateDetails): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentByBranchEvent?sessionId=${LeavingCertificateDetails.sessionId}&&courseId=${LeavingCertificateDetails.courseId}&&branchId=${LeavingCertificateDetails.branchId}&&yearId=${LeavingCertificateDetails.yearId}`);
  }
  GetLeavingCertificate(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetLeavingCertificate`);
  }
  SaveLeavingCertificate(LeavingCertificateDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveLeavingCertificate`, LeavingCertificateDetails)
  }
  UpdateLeavingCertificate(LeavingCertificateDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateLeavingCertificate`, LeavingCertificateDetails)
  }

  //Master-College Details functionality
  SaveMasterCollegeDetails(CollegeDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterCollegeDetails`, CollegeDetails)
  }
  GetFYear(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetFYear`)
  }

  GetCollegeMasterDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetCollegeMasterDetails`)
  }

  

  GetCollegeMasterDetailsById(s1): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCollegeBySchoolId?id=${s1}`)
  }


  updateMasterCollegeDetails(CollegeDetails): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterCollegeDetails`, CollegeDetails)
  }
  DeleteCollege(College): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMultipleSchool?id=${College.schoolId}`)
  }

  // MasterUnit ROUTING
  SaveMasterUnit(MasterUnit): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMasterUnit`, MasterUnit)
  }
  getMasterUnit(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterUnit`)
  }
  UpdateMasterUnit(MasterUnit): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterUnit?id=${MasterUnit.unitId}`, MasterUnit)
  }
  DeleteMasterUnit(MasterUnit): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterUnit?id=${MasterUnit.unitId}`)
  }
  // MasterItem ROUTING
  SaveMasterItem(MasterItem): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMasterItem`, MasterItem)
  }
  getMasterItem(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterItem`)
  }
  UpdateMasterItem(MasterItem): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterItem?id=${MasterItem.itemId}`, MasterItem)
  }
  DeleteMasterItem(MasterItem): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterItem?id=${MasterItem.itemId}`)
  }
  // MasterVendor ROUTING
  SaveMasterVendor(MasterVendor): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMasterVendor`, MasterVendor)
  }
  getMasterVendor(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterVendor`)
  }
  UpdateMasterVendor(MasterVendor): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterVendor?id=${MasterVendor.vendor_ID}`, MasterVendor)
  }
  DeleteMasterVendor(MasterVendor): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterVendor?id=${MasterVendor.vendor_ID}`)
  }
  // DirectStockEntry ROUTING
  saveDirectStockEntry(DirectStockEntry): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveDirectStockEntry`, DirectStockEntry)
  }
  getDirectStockEntry(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDirectStockEntry`)
  }
  getAllItemNamesInStock(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllItemNamesInStock`)
  }
  getBalanceQtyOfItem(itemId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBalanceQtyOfItem?id=${itemId}`)
  }

  // DirectStockIssue ROUTING
  saveDirectStockIssue(DirectStockIssue): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveDirectStockIssue`, DirectStockIssue)
  }
  // Quotation ROUTING
  getVendors(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVendors`)
  }
  getQuotation(search_Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotation?id=${search_Vendor_ID}`)
  }
  getQuotationTransactionData(search_Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationTransactionData?id=${search_Vendor_ID}`)
  }
  CheckQuotation_No(vendor_ID, quotation_No): Observable<any> {
    return this.http.get(`${this.baseUrl}/CheckQuotation_No?id=${quotation_No}&&vendor_ID=${vendor_ID}`)
  }
  saveQuotation(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveQuotation`, Quotation)
  }
  updateQuotation(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateQuotation`, Quotation)
  }
  deleteQuotation(search_Vendor_ID): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteQuotation?id=${search_Vendor_ID}`)
  }

  // QuotationApproval ROUTING
  getAllGroupNames(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllGroupNames`)
  }
  getGroupNames(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getGroupNames`)
  }
  getQuotationApprovalList(groupName): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationApprovalList?id=${groupName}`)
  }
  ApproveQuotation(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/ApproveQuotation`, Quotation)
  }
  // Purchase Order ROUTING
  getQuotationPurchaseOrders(searchVendor): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationPurchaseOrders?id=${searchVendor}`)
  }
  getQuotationIDNO(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationIDNO`)
  }
  getQuotationNumberByVendor(vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationNumberByVendor?id=${vendor_ID}`)
  }

  getQuotationDetails(quotation_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationDetails?id=${quotation_ID}`)
  }
  savePurchaseOrder(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/savePurchaseOrder`, Quotation)
  }
  getPurchaseDetailsTransData(po_NO, quotation_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPurchaseDetailsTransData?id=${po_NO}&&Quotation_ID=${quotation_ID}`)
  }
  updatePurchaseOrder(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/updatePurchaseOrder`, Quotation)
  }
  deletePurchaseOrder(Quotation): Observable<any> {
    return this.http.post(`${this.baseUrl}/deletePurchaseOrder`, Quotation)
  }


  // Store Invoice ROUTING
  checkInvoiceNumber(invoiceNumber): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkInvoiceNumber?id=${invoiceNumber}`)
  }
  getPurchaseOrderNoByVendor(vendorId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPurchaseOrderNoByVendor?id=${vendorId}`)
  }
  getTotalAmountOfQuotationByPOID(poid): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTotalAmountOfQuotationByPOID?id=${poid}`)
  }
  saveStoreInvoice(StoreInvoice): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStoreInvoice`, StoreInvoice)
  }
  searchInvoiceByVendor(vendorId): Observable<any> {
    return this.http.get(`${this.baseUrl}/searchInvoiceByVendor?id=${vendorId}`)
  }

  // StockEntry ROUTING
  saveStockEntry(StockEntry): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStockEntry`, StockEntry)
  }
  getStockEntry(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStockEntry`)
  }
  getInvoiceItemDetails(invoiceId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getInvoiceItemDetails?id=${invoiceId}`)
  }
  getInvoiceNumberByVendor(vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getInvoiceNumberByVendor?id=${vendor_ID}`)
  }

  // Vendor Payment ROUTING
  getVendorInvoicesForPayment(vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVendorInvoicesForPayment?id=${vendor_ID}`)
  }
  changeInvoiceNo(invoiceNo, vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/changeInvoiceNo?id=${invoiceNo}&&vendor_ID=${vendor_ID}`)
  }
  saveVendorPayment(VendorPayment): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveVendorPayment`, VendorPayment)
  }
  // Store Requisition ROUTING
  getUsersByDepartment(department): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUsersByDepartment?id=${department}`)
  }
  getItemListByRequisitionId(requisitionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getItemListByRequisitionId?id=${requisitionId}`)
  }
  getStoreRequisition(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStoreRequisition`)
  }
  saveStoreRequisition(Requisition): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStoreRequisition`, Requisition)
  }
  updateStoreRequisition(Requisition): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateStoreRequisition?id=${Requisition.requisitionId}`, Requisition)
  }
  deleteStoreRequisition(requisitionId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteStoreRequisition?id=${requisitionId}`)
  }
  // Stock Acceptance Requisition ROUTING 
  RejectRequisitionDetails(requisitionId, rejectionReason): Observable<any> {
    return this.http.delete(`${this.baseUrl}/RejectRequisitionDetails?id=${requisitionId}&&rejectionReason=${rejectionReason}`)
  }
  AcceptRequisitionDetails(requisitionId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/AcceptRequisitionDetails?id=${requisitionId}`)
  }

  // Stock Issue ROUTING 
  GetRequisitionNoByDept(RequisitionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetRequisitionNoByDept?id=${RequisitionId}`)
  }
  GetRequisitionNoDetails(RequisitionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetRequisitionNoDetails?id=${RequisitionId}`)
  }
  getItemNoListPendingForTransfer(itemId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getItemNoListPendingForTransfer?id=${itemId}`)
  }
  SaveStockIssueDetails(StockIssueDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveStockIssueDetails`, StockIssueDetails)
  }

  // Expired Stock ROUTING 

  getExpiredStock(fromDate, toDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getExpiredStock?fromDate=${fromDate}&&toDate=${toDate}`)
  }
  saveExpiredStock(ExpiredStock): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveExpiredStock`, ExpiredStock)
  }

  // MasterEvent ROUTING
  SaveMasterEvent(MasterEvent): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMasterEvent`, MasterEvent)
  }
  getMasterEvent(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterEvent`)
  }
  UpdateMasterEvent(MasterEvent): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterEvent?id=${MasterEvent.eventId}`, MasterEvent)
  }
  DeleteMasterEvent(MasterEvent): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterEvent?id=${MasterEvent.eventId}`)
  }
  // MasterSubEvent ROUTING
  saveMasterSubEvent(MasterSubEvent): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterSubEvent`, MasterSubEvent)
  }
  GetMasterSubEvent(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetMasterSubEvent`)
  }
  updateMasterSubEvent(MasterSubEvent): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterSubEvent?id=${MasterSubEvent.subEventId}`, MasterSubEvent)
  }
  deleteMasterSubEvent(MasterSubEvent): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterSubEvent?id=${MasterSubEvent.subEventId}`, MasterSubEvent)
  }
  // MasterActivity ROUTING
  saveMasterActivity(MasterActivity): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterActivity`, MasterActivity)
  }
  getMasterActivity(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterActivity`)
  }
  updateMasterActivity(MasterActivity): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterActivity?id=${MasterActivity.activityId}`, MasterActivity)
  }
  deleteMasterActivity(MasterActivity): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterActivity?id=${MasterActivity.activityId}`)
  }
  // MasterSubActivity ROUTING
  saveMasterSubActivity(MasterSubActivity): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveMasterSubActivity`, MasterSubActivity)
  }
  getMasterSubActivity(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMasterSubActivity`)
  }
  updateMasterSubActivity(MasterSubActivity): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateMasterSubActivity?id=${MasterSubActivity.subActivityId}`, MasterSubActivity)
  }
  deleteMasterSubActivity(MasterSubActivity): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteMasterSubActivity?id=${MasterSubActivity.subActivityId}`)
  }
  // EventDailyNotes ROUTING
  SaveEventDailyNotes(EventDailyNotes): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveEventDailyNotes`, EventDailyNotes)
  }
  getEventDailyNotes(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventDailyNotes`)
  }
  UpdateEventDailyNotes(EventDailyNotes): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEventDailyNotes`, EventDailyNotes)
  }
  DeleteEventDailyNotes(EventDailyNotes): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteEventDailyNotes?id=${EventDailyNotes.noticeId}`)
  }

  // PlannerEventCalender ROUTING

  getSubEventForSelectEvent(eventId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubEventForSelectEvent?id=${eventId}`)
  }
  getTeachers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTeachers`)
  }
  saveEventCalender(EventCalender): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveEventCalender`, EventCalender)
  }
  getEventCalender(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventCalender`)
  }
  getEventCalenderTrans(evCalenderId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventCalenderTrans?id=${evCalenderId}`)
  }

  updateEventCalender(EventCalender): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateEventCalender`, EventCalender)
  }
  deleteEventCalender(evCalenderId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteEventCalender?id=${evCalenderId}`)
  }


  // PlannerActivity ROUTING

  getSubActivityByActivity(activityId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubActivityByActivity?id=${activityId}`)
  }
  saveActivityPlanner(ActivityPlanner): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveActivityPlanner`, ActivityPlanner)
  }
  getActivityPlanner(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getActivityPlanner`)
  }
  getActivityPlannerTrans(plannerId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getActivityPlannerTrans?plannerId=${plannerId}`)
  }
  updateActivityPlanner(ActivityPlanner): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateActivityPlanner?plannerId=${ActivityPlanner.plannerId}`, ActivityPlanner)
  }
  deleteActivityPlanner(plannerId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteActivityPlanner?plannerId=${plannerId}`)
  }


  // Event Details ROUTING
  getEventsBySessionYear(sessionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventsBySessionYear?sessionId=${sessionId}`)
  }
  UpdateEventDetails(EventDetails): Observable<any> {
    // return this.http.put(`${this.baseUrl}/UpdateEventDetails?detailsId=${EventDetails.detailsId}&&details=${EventDetails.details}`)
  
    return this.http.put(`${this.baseUrl}/UpdateEventDetails`,EventDetails)

  }


  // Student Event Participation ROUTING
  getEventByDate(eventDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventByDate?eventDate=${eventDate}`)
  }
  getContactInfoOfStudent(studentId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getContactInfoOfStudent?studentId=${studentId}`)
  }
  saveStudentParticipation(StudentParticipation): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveStudentParticipation`, StudentParticipation)
  }
  getStudentParticipation(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentParticipation`)
  }
  getStudentParticipationTrans(participantId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentParticipationTrans?id=${participantId}`)
  }
  updateStudentParticipation(StudentParticipation): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateStudentParticipation`, StudentParticipation)
  }
  deleteStudentParticipation(participantId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteStudentParticipation?id=${participantId}`)
  }

  // Student Event Winner ROUTING
  getStudentByEventAndSubevent(eventDate, subEventId, eventId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentByEventAndSubevent?eventDate=${eventDate}&&subEventId=${subEventId}&&eventId=${eventId}`)
  }
  getSubEventForSelectEventForThatDate(eventId, eventDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubEventForSelectEventForThatDate?eventDate=${eventDate}&&eventId=${eventId}`)
  }
  saveEventWinnerList(EventWinner): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveEventWinnerList`, EventWinner)
  }

  getEventWinnerList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventWinnerList`)
  }
  deleteEventWinner(winnerId): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteEventWinner?winnerId=${winnerId}`)
  }

  // PlannerViewEvents Routing

  getEventDetailsBySessionDate(sessionId, eventDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventDetailsBySessionDate?eventDate=${eventDate}&&sessionId=${sessionId}`)
  }


  // ActivityStudentAttendance ROUTING
  getActivityDate(courseId, branchId, yearId, sessionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getActivityDate?courseId=${courseId}&&branchId=${branchId}&&yearId=${yearId}&&sessionId=${sessionId}`)
  }
  getActivityByDate(plannerDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getActivityByDate?plannerDate=${plannerDate}`)
  }
  getSubActivityByDate(plannerDate, activityId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSubActivityByDate?plannerDate=${plannerDate}&&activityId=${activityId}`)
  }

  SaveStudentActivityAttendance(StudentActivityAttendance): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveStudentActivityAttendance`, StudentActivityAttendance)
  }
  GetStudentActivityAttendance(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetStudentActivityAttendance`)
  }
  GetStudentActivityAttendanceTrans(attendanceId): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetStudentActivityAttendanceTrans?attendanceId=${attendanceId}`)
  }
  UpdateStudentActivityAttendance(StudentActivityAttendance): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateStudentActivityAttendance?attendanceId=${StudentActivityAttendance.attendanceId}`, StudentActivityAttendance)
  }

  // ------------------------- Transport ------------------
  //------------------------- Vehical Pickup Point------------------

  SaveBusPickupPoint(BusPickUp): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusPickupPoints`, BusPickUp)
  }


  GetBusPickupPoint(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusPickupPoints`)
  }

  UpdateBusPickupPoint(BusPickUp): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateBusPickupPoint?id=${BusPickUp.Station_Id}`, BusPickUp)
  }

  DeleteBusPickupPoint(BusPickUp): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteBusPickupPoint?id=${BusPickUp.Station_Id}`, BusPickUp)
  }


  //------------------------- Vehical Information ------------------

  SaveBusInformation(BusPickUp): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusInformation`, BusPickUp)
  }


  GetBusInformation(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusInformation`)
  }

  UpdateBusInformation(BusInfo): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateBusInformation?id=${BusInfo.bus_Id}`, BusInfo)
  }

  DeleteBusInformation(BusInfo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteBusInformation?bus_Id=${BusInfo.bus_Id}`, BusInfo)

  }

  //------------------------- Driver Information ------------------

  SaveBusDriverInfo(BusPickUp): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusDriverInfo`, BusPickUp)
  }


  GetBusDriverInfo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusDriverInfo`)
  }

  UpdateBusDriverInfo(BusInfo): Observable<any> {

    return this.http.put(`${this.baseUrl}/UpdateBusDriverInfo?id=${BusInfo.Driver_Id}`, BusInfo)
  }

  DeleteBusDriverInfo(BusInfo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteBusDriverInfo?id=${BusInfo.Driver_Id}`, BusInfo)
  }

  //-------------------------Bus Enroll Information ------------------

  SaveBusEnrollInfo(BusPickUp): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusEnrollrInfo`, BusPickUp)
  }


  GetBusEnrollInfo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusEnrollInfo`)
  }

  UpdateBusEnrollInfo(BusEnrollInfo): Observable<any> {

    return this.http.put(`${this.baseUrl}/UpdateBusEnrollnfo?id=${BusEnrollInfo.EnrollId}`, BusEnrollInfo)
  }

  getBusEnrollInfoByEnrollId(BusEnrollInfo): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusEnrollInfoByEnrollId?id=${BusEnrollInfo.EnrollId}`, BusEnrollInfo)
  }

  DeleteBusEnrollInfo(BusEnrollInfo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteBusEnrollInfo?id=${BusEnrollInfo.EnrollId}`, BusEnrollInfo)
  }

  //------------------------- Bus Fuel Maintenance ------------------

  SaveBusFuelMaintenance(BusFuelMaintenance): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusFuel`, BusFuelMaintenance)
  }


  GetBusFuelMaintenance(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusFuel`)
  }

  UpdateBusFuelMaintenance(BusFuelMaintenance): Observable<any> {
    return this.http.put(`${this.baseUrl}/UpdateBusFuel?id=${BusFuelMaintenance.FuelMaint_Id}`, BusFuelMaintenance)
  }
  DeleteBusFuelMaintenance(BusFuelMaintenance): Observable<any> {
    return this.http.delete(`${this.baseUrl}/DeleteBusFuel?id=${BusFuelMaintenance.FuelMaint_Id}`, BusFuelMaintenance)
  }

  //---------------------Bus Routing Info-------------------

  SaveBusRouteInfo(BusRouteInfo): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveBusRouteInfo`, BusRouteInfo)
  }

  GetBusRouteInfo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusRoute`)
  }

  GetRoutebyBusNo(BusRouteInfo): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetRoutebyBusNo?id=${BusRouteInfo.Bus_Id}`, BusRouteInfo)
  }

  GetBusRouteInfoTrans(BusRouteInfo): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBusRouteDetail?id=${BusRouteInfo.Route_Id}`, BusRouteInfo)
  }

  UpdateBusRouteInfo(BusRouteInfo): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateBusRouteInfo?id=${BusRouteInfo.Route_Id}`, BusRouteInfo)
  }

  DeleteBusRouteInfo(BusRouteInfo): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteBusRouteInfo?id=${BusRouteInfo.Route_Id}`, BusRouteInfo)
  }


  // --------------------------------------------- Student Reports ---------------------------------------

  GetStudAdmissionReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudAdmissionReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}`, StudentReports)
  }

  GetStudCategoryReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudCategoryReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}&categoryId=${StudentReports.categoryId}`, StudentReports)
  }

  GetStudBirthReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudBirthReport?birthMonth=${StudentReports.birthMonth}&birthYear=${StudentReports.birthYear}`, StudentReports)
  }

  GetStudBranchReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudBranchReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}`, StudentReports)
  }
//start
  GetStudAttendanceReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudAttendanceReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}&attDate=${StudentReports.attDate}`, StudentReports)
  }

  GetSemStrengthReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSemStrengthReport?sessionId=${StudentReports.sessionId}`, StudentReports)
  }

  GetStudBatchReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudBatchReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}&batchId=${StudentReports.batchId}`, StudentReports)
  }

  GetStudDetailsReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudDetailsReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}&studentId=${StudentReports.studentId}`, StudentReports)
  }

  GetFeeDetailsReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeDetailsReport?sessionId=${StudentReports.sessionId}`, StudentReports)
  }

  GetFeeDetailsByBranch(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeDetailsByBranch?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}`, StudentReports)
  }

  GetFeeDetailsByYear(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeDetailsByYear?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}`, StudentReports)
  }

  GetFeeDetailsByStudent(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeeDetailsByStudent?sessionId=${StudentReports.AdmissionNo}`, StudentReports)
  }

  GetStudICartReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudICartReport?studentId=${StudentReports.studentId}`, StudentReports)
  }

  checkLastWorkingDate(currentDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/checkLastWorkingDate?currentDate=${currentDate}`);
  }

  GetStudLeavingReport(StudentReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudLeavingReport?sessionId=${StudentReports.sessionId}&courseId=${StudentReports.courseId}&branchId=${StudentReports.branchId}&yearId=${StudentReports.yearId}&semesterId=${StudentReports.semesterId}`, StudentReports)
  }

  // -------------- Hostel Report -----------------------
  GetHostelRoomReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelRoomReport?hostelId=${HostelReports.hostelId}`, HostelReports)
  }
  GetRoomAllocationReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRoomAllocationReport?sessionId=${HostelReports.sessionId}&courseId=${HostelReports.courseId}&branchId=${HostelReports.branchId}&yearId=${HostelReports.yearId}&hostelId=${HostelReports.hostelId}`, HostelReports)
  }

  GetStudRoomAllocationReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudRoomAllocationReport?sessionId=${HostelReports.sessionId}&hostelId=${HostelReports.hostelId}&roomNo=${HostelReports.roomNo}&studentId=${HostelReports.studentId}`, HostelReports)
  }

  GetRoomTransferReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRoomTransferReport?sessionId=${HostelReports.sessionId}&yearId=${HostelReports.yearId}&hostelId=${HostelReports.hostelId}`, HostelReports)
  }

  GetHostelVisitorReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelVisitorReport?hostelId=${HostelReports.hostelId}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }

  GetHostelExpenseReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelExpenseReport?hostelId=${HostelReports.hostelId}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }

  GetHostelOutpassReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelOutpassReport?ostatus=${HostelReports.ostatus}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }

  GetHostelAttendanceReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getHostelAttendanceReport?hostelId=${HostelReports.hostelId}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }


  // ------------------- Lab Loss Report ---------------------------
  GetLabLossReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLabLossReport?labId=${HostelReports.labId}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }

  GetLabRequisitionReport(HostelReports): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLabRequisitionReport?id=${HostelReports.labId}&fromDate=${HostelReports.fromDate}&toDate=${HostelReports.toDate}`, HostelReports)
  }

  // ------------------- Front Desk Report ---------------------------
  GetInwardLetterReport(FrontDeskReport): Observable<any> {
    return this.http.get(`${this.baseUrl}/getInwardLetterReport?FromDate=${FrontDeskReport.fromDate}&ToDate=${FrontDeskReport.toDate}`, FrontDeskReport)
  }

  GetOutwardLetterReport(FrontDeskReport): Observable<any> {
    return this.http.get(`${this.baseUrl}/getOutwardLetterReport?FromDate=${FrontDeskReport.fromDate}&ToDate=${FrontDeskReport.toDate}`, FrontDeskReport)
  }

  // ---------------- User Report ----------------------------------------
  GetUserReport(UserReport): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUserReport?rollType=${UserReport.rollType}`, UserReport)

  }

  // --------- Academic result -----------------------------------------------
  GetStudentForAcademicResult(AcademicResult): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentForAcademicResult?id=${AcademicResult.sessionId}&courseId=${AcademicResult.courseId}&branchId=${AcademicResult.branchId}&yearId=${AcademicResult.yearId}&semesterId=${AcademicResult.semesterId}&subjectId=${AcademicResult.subjectId}`, AcademicResult)
  }

  UploadResultExcel(AcademicResult): Observable<any> {
    return this.http.put(`${this.baseUrl}/uploadResultExcel`, AcademicResult)
  }

  getAttempts(StudMarksheet): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAttempts?sessionId=${StudMarksheet.sessionId}&courseId=${StudMarksheet.courseId}&branchId=${StudMarksheet.branchId}&yearId=${StudMarksheet.yearId}&semesterId=${StudMarksheet.semesterId}&studentId=${StudMarksheet.studentId}`, StudMarksheet)
  }


  GetStudMarksheet(StudMarksheet): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudMarksheet?sessionId=${StudMarksheet.sessionId}&courseId=${StudMarksheet.courseId}&branchId=${StudMarksheet.branchId}&yearId=${StudMarksheet.yearId}&semesterId=${StudMarksheet.semesterId}&studentId=${StudMarksheet.studentId}&attempts=${StudMarksheet.attempts}`, StudMarksheet)
  }

  // *****
  GetAttemptsForEdit(EditAcademicResult): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAttemptsForEdit?sessionId=${EditAcademicResult.sessionId}&courseId=${EditAcademicResult.courseId}&branchId=${EditAcademicResult.branchId}&yearId=${EditAcademicResult.yearId}&semesterId=${EditAcademicResult.semesterId}&subjectId=${EditAcademicResult.subjectId}`, EditAcademicResult)
  }

  GetAcademicResult(EditAcademicResult): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetAcademicResult?sessionId=${EditAcademicResult.sessionId}&courseId=${EditAcademicResult.courseId}&branchId=${EditAcademicResult.branchId}&yearId=${EditAcademicResult.yearId}&semesterId=${EditAcademicResult.semesterId}&subjectId=${EditAcademicResult.subjectId}&attempt=${EditAcademicResult.attempt}`, EditAcademicResult)
  }

  UpdateAcademicResult(EditAcademicResult): Observable<any> {
    return this.http.put(`${this.baseUrl}/updateAcademicResult?id=${EditAcademicResult.result_Id}`, EditAcademicResult)
  }


  // --------------------Employee Report -----------------------------
  getEmpConsolidatedReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmpConsolidatedReport?departmentId=${Report.departmentId}&&designationId=${Report.designationId}&&employeeType=${Report.EmployeeType}&&profession=${Report.Profession}&&subCastId=${Report.subCastId}&&castId=${Report.castId}&&categoryId=${Report.categoryId}&&religionId=${Report.religionId}&&gender=${Report.Gender}&&cityId=${Report.cityId}&&districtId=${Report.districtId}&&stateId=${Report.stateId}&&countryId=${Report.countryId}&&staffType=${Report.StaffType}&&employeeId=${Report.employeeId}&&maritalStatus=${Report.MaritalStatus}`)
  }
  getEmployeeAttendanceReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeAttendanceReport?departmentId=${Report.departmentId}&&designationId=${Report.designationId}&&toDate=${Report.toDate}&&fromDate=${Report.fromDate}`)
  }
  getEmployeeBirthdayReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeBirthdayReport?departmentId=${Report.departmentId}&&month=${Report.Month}&&year=${Report.Year}`)
  }
  getGradeWiseEmployeeReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getGradeWiseEmployeeReport?gradeId=${Report.gradeId}`)
  }
  getEmployeeLeaveReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeLeaveReport?employeeId=${Report.employeeId}&&status=${Report.Status}&&toDate=${Report.toDate}&&fromDate=${Report.fromDate}`)
  }
  getEmployeeWiseUserIdAndPasswordReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeWiseUserIdAndPasswordReport?employeeId=${Report.employeeId}`)
  }


  //-----------------------------------------LibraryBook Discontinue Routing

  getAccesstionNoAvailableByType(Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAccesstionNoAvailableByType?Type=${Type}`)
  }
  bookDiscontinue(bookDiscontinueDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/bookDiscontinue`, bookDiscontinueDetails)
  }
  //-----------------------------------------LibraryBook Fine Routing
  getUserFineDetails(user_id, Type): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUserFineDetails?Type=${Type}&&user_id=${user_id}`)
  }
  payLibraryFine(LibraryFineDetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/payLibraryFine`, LibraryFineDetails)
  }

  //-----------------------------------------LibraryBook Vendor Payment Routing


  getvendorLibraryInvoiceDetails(Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getvendorLibraryInvoiceDetails?Vendor_ID=${Vendor_ID}`)
  }
  payLibraryVendorPayment(LibraryVendorPayment): Observable<any> {
    return this.http.post(`${this.baseUrl}/payLibraryVendorPayment`, LibraryVendorPayment)
  }
  // --------------------Library Report -----------------------------
  getLibraryInvoiceByVendor(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryInvoiceByVendor?vendorId=${Report.vendorId}`)
  }
  getLibraryInvoiceReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryInvoiceReport?visReport=${Report.visReport}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}&&vendorId=${Report.vendorId}&&invoiceId=${Report.invoiceId}`)
  }
  // --------------------Library Invoice Payment Report -----------------------------
  getLibraryInvoicePaymentReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLibraryInvoicePaymentReport?visReport=${Report.visReport}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}&&vendorId=${Report.vendorId}&&invoiceId=${Report.invoiceId}`)
  }
  // --------------------Library Details Report -----------------------------

  getBookBindingReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBookBindingReport?visReport=${Report.visReport}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  getCourseWiseBookIssueReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCourseWiseBookIssueReport?visReport=${Report.visReport}&&sessionId=${Report.sessionId}&&courseId=${Report.courseId}&&branchId=${Report.branchId}&&yearId=${Report.yearId}&&semesterId=${Report.semesterId}`)
  }
  getIssuedBooksFromLibraryReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIssuedBooksFromLibraryReport?visReport=${Report.visReport}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  getBookCategoryReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBookCategoryReport?id=${Report.bookCategoryId}`)
  }
  getStudentFineReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentFineReport?visReport=${Report.visReport}&&sessionId=${Report.sessionId}&&courseId=${Report.courseId}&&branchId=${Report.branchId}&&yearId=${Report.yearId}&&semesterId=${Report.semesterId}&&user_id=${Report.user_id}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  getAvailableBooksFromLibraryReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAvailableBooksFromLibraryReport?bookCategoryId=${Report.bookCategoryId}`)
  }

  // --------------------Event Report -----------------------------

  getDailyNotesReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDailyNotesReport?fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  EventDetailsReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/EventDetailsReport?sessionId=${Report.sessionId}`)
  }
  getEventParticitantReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventParticitantReport?sessionId=${Report.sessionId1}&&studentType=${Report.studentType}&&eventId=${Report.eventId}&&subEventId=${Report.subEventId}`)
  }
  getAcivityPlannerReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAcivityPlannerReport?sessionId=${Report.sessionId1}&&courseId=${Report.courseId}&&branchId=${Report.branchId}&&yearId=${Report.yearId}&&fromDate=${Report.fromDate}&&todate=${Report.toDate}`)
  }
  getAllActivityDate(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAllActivityDate`)
  }
  getStudentAcivityAttendanceReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getStudentAcivityAttendanceReport?activityDate=${Report.activityDate}&&subActivityId=${Report.subActivityId}&&activityId=${Report.activityId}`)
  }
  getEventDateList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventDateList`)
  }
  getEventByDateReport(eventDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEventByDateReport?eventDate=${eventDate}`)
  }
  getWinnerReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getWinnerReport?sessionId=${Report.sessionId1}&&eventDate=${Report.eventDate}&&eventId=${Report.eventId}&&subEventId=${Report.subEventId}`)
  }
  // --------------------Store Report -----------------------------

  getRequisitionReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getRequisitionReport?DepartmentId=${Report.departmentId}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}&&Status=${Report.status}`)
  }
  getExpiredItemReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getExpiredItemReport?ItemId=${Report.itemId}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  getInvoiceBetweenDateReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getInvoiceBetweenDateReport?InvoiceId=${Report.invoiceId}&&VendorId=${Report.vendorId}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
  }
  getPaymentforVendorReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPaymentforVendorReport?invoiceId=${Report.invoiceId1}&&vendorId=${Report.vendorId1}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
    }
    getQuotationComparisonReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationComparisonReport?groupName=${Report.groupName}&&fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
    }
    getQuotationNoByGroupName(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationNoByGroupName?groupName=${Report.groupName}`)
    }
    getQuotationReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQuotationReport?groupName=${Report.groupName}&&quotationNo=${Report.quotationNo}`)
    }
    getReorderLevelReport(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getReorderLevelReport`)
    }
    getTotalStockIssueReport(Report): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTotalStockIssueReport?fromDate=${Report.fromDate}&&toDate=${Report.toDate}`)
    }
    getCurrentStockReport(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCurrentStockReport`)
    }
    getItemDetailsReport(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getItemDetailsReport`)
    }
    getVendorDetailsReport(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVendorDetailsReport`)
    }


  LoginUserEmp(Login): Observable<any> {
    return this.http.post(`${this.baseUrl}/LoginUserEmp?id=${Login.UserName}&Password=${Login.Password1}`, Login)
  }

  getEmployeeAccessUrls(roleId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getEmployeeAccessUrls?roleId=${roleId}`);
  }

  getCurrentSessionName(sessionId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCurrentSessionName?sessionId=${sessionId}`);
  }

  
  
  getUserAccessUrls(role, user_id): Observable<any> {
    return this.http.get(`${this.baseUrl}/getUserAccessUrls?user_id=${user_id}&&role=${role}`);
  }
  updateEmpPassword(EmpPassword): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateEmpPassword`, EmpPassword);
  }
  updateStudentPassword(StudentPassword): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateStudentPassword`, StudentPassword);
  }
  getInvoiceNumberByVendorInReport(Vendor_ID): Observable<any> {
    return this.http.get(`${this.baseUrl}/getInvoiceNumberByVendorInReport?id=${Vendor_ID}`)  }
    
    getPurchaseRegisterReport(fromDate,toDate): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPurchaseRegisterReport?fromDate=${fromDate}&&toDate=${toDate}`)  }
    
    // -------- DashboardComponent ------------------

    GetAllCount(schoolId): Observable<any> {
      return this.http.get(`${this.baseUrl}/getAllCount?id=${schoolId}`)
    }
    
    GetErpUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetErpUsers`)
  }
}