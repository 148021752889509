import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ErrorService } from '../error.service';
import { UmsService } from '../ums.service';

@Component({
  selector: 'app-jsonvalidation',
  templateUrl: './jsonvalidation.component.html',
  styleUrls: ['./jsonvalidation.component.css']
})
export class JsonvalidationComponent implements OnInit {

  form: FormGroup;
  constructor(public formBuilder: FormBuilder,
    public UmsApi: UmsService,public errorService: ErrorService) { }

  ngOnInit() {

  
    this.form = this.formBuilder.group({
      jsonValidId: new FormControl(),
      name: new FormControl(''),
      rate: new FormControl(''),
      email: new FormControl('')
  })
} 
  save()
  {
    this.UmsApi.save(this.form.value).subscribe(response => {
      
      if (response == true) {
        alert("Save Successfully");
      }
      else{
        //alert("not save")
        this.errorService.renderServerErrors(this.form, response);
      }
    
       
    }, error => {
      console.log(error);
    });
  }


}

