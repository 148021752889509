import { Component, OnInit } from '@angular/core';
import { UmsService } from '../ums.service';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  UserAccessUrls: any[];

  constructor(private router: Router, public UmsApi: UmsService) { }

  ngOnInit() {
    if (localStorage.Role == 'E') {
      this.getUserAccessUrls(2, 0);
    }
    else if (localStorage.Role == 'S') {
      this.getUserAccessUrls(3, 0);
    }
    else {
      this.getUserAccessUrls(1, localStorage.LS_UserId);
    }

  }
  Logout() {
    localStorage.LS_RoleId = '';
    localStorage.LS_SchoolId = '';
    localStorage.LS_UserId = '';

    this.router.navigate(['/UserLogin']);

    setTimeout(() => {
      $("#HeaderId").hide();
      $("#SidebarId").hide();
      var element = document.getElementById("ContentWp");
      element.classList.remove("content-wrapper");
    }, 100);

  }
  getUserAccessUrls(role, userid) {
    this.UmsApi.getUserAccessUrls(role, userid).subscribe(response => {
      if (response.status) {
        var MenuSubMenu = [];
        this.UserAccessUrls = [];
        MenuSubMenu = response.data.UserAccessUrls.MenuSubMenu;
        if (MenuSubMenu.length > 0) {
          var Menu = response.data.UserAccessUrls.Menu;
          var SubMenu = response.data.UserAccessUrls.SubMenu;
          var currentSubMenu = MenuSubMenu[0].subMenu;
          var currentMenu = MenuSubMenu[0].menu;
          var subMenuIndex = 0;
          var menuIndex = 0;
          var menuList = [];
          var submenuList = [];
          var urlList = [];
          var count = 0;
          for (var i = 0; i < Menu.length;) {
            for (var j = 0; j < SubMenu.length;) {
              for (var k = 0; k < MenuSubMenu.length; k++) {
                if (MenuSubMenu[k].menu == Menu[i].menu && MenuSubMenu[k].subMenu == SubMenu[j].subMenu) {
                  
                  if (currentMenu == Menu[i].menu) {
                    if (currentSubMenu == SubMenu[j].subMenu) {
                      if (currentSubMenu == "") {
                        submenuList.push({
                          "submenu": "",
                          "urlName": MenuSubMenu[k].urlName,
                          "url": MenuSubMenu[k].url,
                          "urlList": []
                        })
                      }
                      else {
                        urlList.push({
                          "urlName": MenuSubMenu[k].urlName,
                          "url": MenuSubMenu[k].url,
                        })
                      }
                    }
                    else {
                      if (currentSubMenu == "") {
                      }
                      else {
                        if(urlList.length > 0)
                        {
                          submenuList.push({
                            "submenu": currentSubMenu,
                            "urlList": urlList
                          })
                          urlList = [];
                        }
                      }
                      if (MenuSubMenu[k].subMenu == "") {
                        submenuList.push({
                          "submenu": "",
                          "urlName": MenuSubMenu[k].urlName,
                          "url": MenuSubMenu[k].url,
                          "urlList": []
                        })

                      }
                      else {
                        urlList.push({
                          "urlName": MenuSubMenu[k].urlName,
                          "url": MenuSubMenu[k].url,
                        })
                      }
                      currentSubMenu = MenuSubMenu[k].subMenu;
                    }
                  }
                  else {
                    if (urlList.length > 0) {
                      submenuList.push({
                        "submenu": currentSubMenu,
                        "urlList": urlList
                      })
                    }
                    menuList.push({
                      "menu": currentMenu,
                      "submenu": submenuList
                    })
                    submenuList = [];
                    urlList = [];
                    if (MenuSubMenu[k].subMenu == "") {
                      submenuList.push({
                        "submenu": "",
                        "urlName": MenuSubMenu[k].urlName,
                        "url": MenuSubMenu[k].url,
                        "urlList": []
                      })
                    }
                    else {
                      urlList.push({
                        "urlName": MenuSubMenu[k].urlName,
                        "url": MenuSubMenu[k].url,
                      })
                    }
                    currentMenu = MenuSubMenu[k].menu;
                    currentSubMenu = MenuSubMenu[k].subMenu;
                  }
                }
              }
              j++;
            }
            currentMenu = Menu[i].menu;
            i++;
          }
          this.UserAccessUrls =  menuList;
        }
        else {
          alert("No Urls are Asigned to you. Please Contact admin.");
        }

      }
      else {
        this.UserAccessUrls = [];
      }

      // if(currentMenu == Menu[i].menu)
      //               {
      //                 if(SubMenu[j].subMenu == "")
      //                 {
      //                   tempSubmenu.push(
      //                     {
      //                       "subMenu" : "",
      //                       "urlName" : MenuSubMenu[k].urlName,
      //                       "url" : MenuSubMenu[k].url,
      //                       "urlList" : []

      //                     }
      //                   );
      //                   // subMenuIndex++;
      //                 }
      //                 else
      //                 {
      //                   urlList.push(
      //                     {
      //                       "urlName" : MenuSubMenu[k].urlName,
      //                       "url" : MenuSubMenu[k].url,
      //                     }

      //                   )
      //                   subMenuIndex++;
      //                 }
      //               }
      //               else
      //               {
      //                 subMenuIndex = 0;
      //                 this.UserAccessUrls.push(tempSubmenu);
      //                 tempSubmenu = [];
      //               }
    }, error => {
      console.log(error);
    });
  }
}
