import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

import { LoginComponent } from './login/login.component'
import { EmpLoginComponent } from './emp-login/emp-login.component'
import { StudentLoginComponent } from './student-login/student-login.component'
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeStudentPasswordComponent } from './change-student-password/change-student-password.component';
import { JsonvalidationComponent } from './jsonvalidation/jsonvalidation.component';

const routes: Routes = [
  // { path: '', component: LoginComponent },
  { path: '', component: DashboardComponent },
  { path: 'UserLogin', component: LoginComponent },
  { path: 'EmpLogin', component: EmpLoginComponent },
  { path: 'StudLogin', component: StudentLoginComponent },
  { path: 'changePassword', component: ChangePasswordComponent },
  { path: 'changeStudentPassword', component: ChangeStudentPasswordComponent },

  {path:'jsonvalidation',component:JsonvalidationComponent},

  { path : 'Masters', loadChildren : './Module/master.module#MasterModule' },
  { path : 'Employee', loadChildren : './Module/employee.module#EmployeeModule'},
  { path : 'Student', loadChildren : './Module/student.module#StudentModule' },
  { path : 'Library', loadChildren : './Module/library.module#LibraryModule' },
  { path : 'Lab', loadChildren : './Module/lab.module#LabModule' },
  { path : 'Transport', loadChildren : './Module/transport.module#TransportModule' },
  { path : 'Store', loadChildren : './Module/store.module#StoreModule' },
  { path : 'Events', loadChildren : './Module/events.module#EventsModule' },
  { path : 'Report', loadChildren : './Module/report.module#ReportModule' },
  { path : 'Hostel', loadChildren : './Module/hostel.module#HostelModule' },
  { path : 'Result', loadChildren : './Module/result.module#ResultModule' },
  { path : 'FrontDesk', loadChildren : './Module/front-desk.module#FrontDeskModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
