import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UmsService } from '../ums.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // pieChartData: number[];
  // pieChartLabels:String[];
  // pieChartType:String;
  FemaleCount: any;
  StudentCount: any = [];
  StockCount: any = [];

  public pieChartLabels: string[] = ['Male', 'Female'];
  public pieChartData: number[] = [40, 20];
  public pieChartType: string = 'pie';

  public StockpieChartLabels: string[] = ['Pen', 'Table'];
  public StockpieChartData: number[] = [10, 20];
  public StockpieChartType: string = 'pie';

  constructor(public formBuilder: FormBuilder,
    public UmsApi: UmsService) { }

  ngOnInit() {
   this.GetAllCount();
  }

  GetAllCount() {

    var schoolId = localStorage.LS_SchoolId;
    this.UmsApi.GetAllCount(schoolId).subscribe(response => {
      
      if (response.result) {
        this.StudentCount = response.result.studentPieChart;
        this.pieChartData = [this.StudentCount[0].male, this.StudentCount[0].female];

        this.StockCount = response.result.stockPieChart;
        this.StockpieChartLabels =[]
        this.StockpieChartData =[]
      
        for (let i = 0; i < this.StockCount.length; i++) {
          this.StockpieChartLabels.push(this.StockCount[i].itemName);
          this.StockpieChartData.push(this.StockCount[i].availableQty);
        }

      }
      else {
        this.StudentCount = [];
        this.StockCount=[];
      }
    }, error => {
      console.log(error);
    });
  }

}
