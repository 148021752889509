import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
//import { NotificationService } from "./notification.service";

@Injectable({
    providedIn: 'root'
  })
export class ErrorService{

    // constructor(private notificationService: NotificationService) { }
    constructor() { }

    renderServerErrors(form: FormGroup, response: any) {
        alert("error service");
        //response.error.errors.forEach((element: any) => {
            // field errors are handled here, different errors should be handled globally
            //if (element.type === 'field-validation-error') {

                alert("res list="+JSON.stringify(response))          
               

              for(let i=0;i < response.length; i++)
              {  
                const fieldName = response[i].field;
                const message = response[i].message;

                alert("fieldName="+JSON.stringify(response[i].field))
                alert("message="+JSON.stringify(response[i].message))

                  if (form == null || !this.hasFieldName(form, fieldName)) {
                    //this.notificationService.error(`${fieldName}: ${message}`);
                    alert(response[i].message)
                  } 
                  else {
                    this.setFieldError(form, fieldName, message);
                  }
                // } else {
                //   const message = element.message;
                //   this.notificationService.error(message);
                // }
              //});
             }
    }

    hasWrongValue(form: FormGroup, fieldName: string): boolean {
        return this.getFieldErrors(form, fieldName).length > 0;
      }
    
      // a field is correct only if it is filled and have no errors
      hasCorrectValue(form: FormGroup, fieldName: string): boolean {
        const control = this.findFieldControl(form, fieldName);
        // field found && user changed it && it doesn't hold a wrong value
        const isCorrect = control && !control.pristine && !this.hasWrongValue(form, fieldName);
    
        return isCorrect;
      }
    
      // right now we are rendering one error only for a field
      getFieldError(form: FormGroup, fieldName: string): string {
        return this.getFieldErrors(form, fieldName)[0];
      }
    
      getFieldErrors(form: FormGroup, fieldName: string): string[] {
        const control = this.findFieldControl(form, fieldName);
        if ((control && control.errors) || control.untouched) {
          return this.getErrors(control);
        } else {
          return [];
        }
      }
    
      getErrors(control: AbstractControl): string[] {
        return Object.keys(control.errors)
          .filter((error: any) => control.errors[error])
          .map((error: any) => {
            const params = control.errors[error];
            return error;
          });
      }
    
      private hasFieldName(form: FormGroup, fieldName: string): boolean {
        const control = this.findFieldControl(form, fieldName);
        return control != null;
      }
    
      private setFieldError(form: FormGroup, fieldName: string, message: string) {
        const control = this.findFieldControl(form, fieldName);
        // TODO: find a better way to set the error message
        //alert("setFieldError="+JSON.stringify(fieldName))
        const errors = { [message]: true };
        control.setErrors(errors);  
      }
    
      private findFieldControl(form: FormGroup, fieldName: string): AbstractControl {
        return form.get(fieldName);
      }
}